import React from "react";
import MainLayouts from "../layout/MainLayouts";

const NotFound = () => {
  return (
    <MainLayouts>
    <div className="flex flex-col items-center justify-center h-screen ">
      <h1 className="text-4xl font-bold text-red-500">404 - Not Found</h1>
      <p className="text-xl text-gray-600">
        The page you are looking for does not exist.
      </p>
    </div>
    </MainLayouts>
  );
};

export default NotFound;
