import React, { useState } from "react";
import {
  PiBookFill,
  PiCertificateFill,
  PiGearFill,
  PiPenNibFill,
} from "react-icons/pi";

import VonSettings from "../AllVon/VonSettings/VonSettings";
import MyCourse from "./MyCourse";
import EventSection from "./EventSection";

const Profile = () => {
  //   My code
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <>
      <div className="mx-[5%] md:mx-[7%] mt-[30%] md:mt-[15%]">
        <nav className="flex bg-[#2c75e4] text-white flex-wrap mb-[4%]">
          <div
            className={
              toggleState === 1
                ? "flex justify-center items-center gap-2 py-[2%] flex-1 px-[2%] cursor-pointer bg-white border-t-[#2c75e4] border-t-2 border-t-solid text-[#2c75e4] text-sm font-medium"
                : "flex justify-center items-center gap-2 py-[2%] flex-1 px-[2%] cursor-pointer bg-[#2c75e4] border-t-[#2c75e4] border-t-2 border-t-solid hover:bg-white hover:border-t-solid hover:border-t-2 hover:border-t-[#2c75e4] delay-0 duration-150 transition-colors ease-in hover:text-[#2c75e4] text-sm font-medium"
            }
            onClick={() => toggleTab(1)}
          >
            <p className="hidden sm:block">ENROLLED COURSES</p>
            <PiBookFill className="sm:hidden" />
          </div>
          <div
            className={
              toggleState === 2
                ? "flex justify-center items-center gap-2 py-[2%] flex-1 px-[2%] cursor-pointer bg-white border-t-[#2c75e4] border-t-2 border-t-solid text-[#2c75e4] text-sm font-medium"
                : "flex justify-center items-center gap-2 py-[2%] flex-1 px-[2%] cursor-pointer bg-[#2c75e4] border-t-[#2c75e4] border-t-2 border-t-solid hover:bg-white hover:border-t-solid hover:border-t-2 hover:border-t-[#2c75e4] delay-0 duration-150 transition-colors ease-in hover:text-[#2c75e4] text-sm font-medium"
            }
            onClick={() => toggleTab(2)}
          >
            <p className="hidden sm:block">SETTINGS</p>
            <PiGearFill className="sm:hidden" />
          </div>
          <div
            className={
              toggleState === 3
                ? "flex justify-center items-center gap-2 py-[2%] flex-1 px-[2%] cursor-pointer bg-white border-t-[#2c75e4] border-t-2 border-t-solid text-[#2c75e4] text-sm font-medium"
                : "flex justify-center items-center gap-2 py-[2%] flex-1 px-[2%] cursor-pointer bg-[#2c75e4] border-t-[#2c75e4] border-t-2 border-t-solid hover:bg-white hover:border-t-solid hover:border-t-2 hover:border-t-[#2c75e4] delay-0 duration-150 transition-colors ease-in hover:text-[#2c75e4] text-sm font-medium"
            }
            onClick={() => toggleTab(3)}
          >
            <p className="hidden sm:block">MY ASSIGNMENTS</p>
            <PiPenNibFill className="sm:hidden" />
          </div>
          <div
            className={
              toggleState === 4
                ? "flex justify-center items-center gap-2 py-[2%] flex-1 px-[2%] cursor-pointer bg-white border-t-[#2c75e4] border-t-2 border-t-solid text-[#2c75e4] text-sm font-medium"
                : "flex justify-center items-center gap-2 py-[2%] flex-1 px-[2%] cursor-pointer bg-[#2c75e4] border-t-[#2c75e4] border-t-2 border-t-solid hover:bg-white hover:border-t-solid hover:border-t-2 hover:border-t-[#2c75e4] delay-0 duration-150 transition-colors ease-in hover:text-[#2c75e4] text-sm font-medium"
            }
            onClick={() => toggleTab(4)}
          >
            <p className="hidden sm:block">CERTIFICATES</p>
            <PiCertificateFill className="sm:hidden" />
          </div>
        </nav>

        <div className={toggleState === 1 ? "block" : "hidden"}>
          <h3 className="text-[30px] font-bold text-[#273044] mb-[2.5%]">
            Enrolled Courses
          </h3>
          <div className="relative w-full flex items-center before:absolute before:w-[4.5%] before:left-0 before:h-[3px] before:bg-[#d15242] after:absolute after:bg-[#dcdcdc] after:h-[1px] after:w-[94%] after:right-0 mb-[2%]"></div>
          <MyCourse />
        </div>
        <div className={toggleState === 2 ? "block mb-[5%]" : "hidden"}>
          <h3 className="text-[30px] font-bold text-[#273044] mb-[2.5%]">
            Profile Settings
          </h3>
          <div className="relative w-full flex items-center before:absolute before:w-[4.5%] before:left-0 before:h-[3px] before:bg-[#d15242] after:absolute after:bg-[#dcdcdc] after:h-[1px] after:w-[94%] after:right-0 mb-[2%]"></div>
          <VonSettings />
        </div>

        <div
          className={toggleState === 3 ? "block" : "hidden"}
          style={{
            minHeight: "100vh",
          }}
        >
          <h3 className="text-[30px] font-bold text-[#273044] mb-[2.5%]">
            Assignments
          </h3>
          <div className="relative w-full flex items-center before:absolute before:w-[4.5%] before:left-0 before:h-[3px] before:bg-[rgb(56,91,206)] after:absolute after:bg-[#dcdcdc] after:h-[1px] after:w-[94%] after:right-0 mb-[2%]"></div>
          {/* <p className="mb-[5%] text-[#273044]">
            You don't have any assignments yet
          </p> */}
          <EventSection />
        </div>

        <div
          className={toggleState === 4 ? "block" : "hidden"}
          style={{
            minHeight: "100vh",
          }}
        >
          <h3 className="text-[30px] font-bold text-[#273044] mb-[2.5%]">
            Certificates
          </h3>
          <div className="relative w-full flex items-center before:absolute before:w-[4.5%] before:left-0 before:h-[3px] before:bg-[#d15242] after:absolute after:bg-[#dcdcdc] after:h-[1px] after:w-[94%] after:right-0 mb-[2%]"></div>
          <p className="mb-[5%] text-[#273044]">
            You don't have a certificate yet
          </p>
        </div>
      </div>
      {/* <div className="custom-course-background">
        <div className="max-w-6xl py-10 mx-auto">
          <div className="flex-wrap px-4 lg:flex gap-14 ">
            <div className="w-full mb-4 lg:w-1/5 ">
              <ul className="bg-gray-300">
                <li
                  className={`hover:border-l-4 border-b ${
                    activeTab === "profile" ? "border-blue-500" : ""
                  }`}
                  onClick={() => handleTabChange("profile")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="block p-2">
                    <p>Profile</p>
                  </div>
                </li>

                <li
                  className={`hover:border-l-4 border-b ${
                    activeTab === "email" ? "border-blue-500" : ""
                  }`}
                  onClick={() => handleTabChange("email")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="block p-2">
                    <p>Email</p>
                  </div>
                </li>

                <li
                  className={`hover:border-l-4 border-b ${
                    activeTab === "password" ? "border-blue-500" : ""
                  }`}
                  onClick={() => handleTabChange("password")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="block p-2">
                    <p>Password</p>
                  </div>
                </li>

                <li
                  className={`hover:border-l-4 border-b ${
                    activeTab === "notification" ? "border-blue-500" : ""
                  }`}
                  onClick={() => handleTabChange("notification")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="block p-2">
                    <p>Notification</p>
                  </div>
                </li>

                <li
                  className={`hover:border-l-4 border-b ${
                    activeTab === "billing" ? "border-blue-500" : ""
                  }`}
                  onClick={() => handleTabChange("billing")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="block p-2">
                    <p>Billing</p>
                  </div>
                </li>

                <li
                  className={`hover:border-l-4 border-b ${
                    activeTab === "order" ? "border-blue-500" : ""
                  }`}
                  onClick={() => handleTabChange("order")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="block p-2">
                    <p>Order History</p>
                  </div>
                </li>

                <li
                  className={`hover:border-l-4 border-b ${
                    activeTab === "certificate" ? "border-blue-500" : ""
                  }`}
                  onClick={() => handleTabChange("certificate")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="block p-2">
                    <p>Certificate</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-3/4">
              {activeTab === "profile" && (
                <>
                  <h1 className="text-4xl font-bold py-3 ">Edit Profile </h1>
                  <div className="flex">
                    <div className="w-1/5 space-y-3">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        id="image-upload-input"
                        name="profile_img" // Add the name attribute here
                        style={{ display: "none" }}
                      />

                      <label htmlFor="image-upload-input">
                        <img
                          src={
                            profile_img
                              ? URL.createObjectURL(profile_img)
                              : imageUrl
                          }
                          alt="image-url"
                          className="lg:h-32 lg:w-32 h-16 w-16 rounded mx-auto"
                        />
                      </label>
                      <div className="flex justify-center">
                        <label
                          className="bg-custom-button p-2 rounded text-white"
                          htmlFor="image-upload-input"
                        >
                          Upload Image
                        </label>
                      </div>
                    </div>

                    <div className="w-3/4 px-4">
                      <form onSubmit={handleFormSubmit}>
                        <div className="mb-4">
                          <div className="flex flex-col gap-4 md:flex-row ">
                            <div className="lg:w-1/2">
                              <label htmlFor="FirstName">First Name</label>
                              <input
                                type="text"
                                name="FirstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                                required
                                className="w-full px-3 py-2 bg-gray-100 border rounded focus:outline-none focus:border-blue-400"
                              />
                            </div>
                            <div className="lg:w-1/2">
                              <label htmlFor="LastName">Last Name</label>
                              <input
                                type="text"
                                name="LastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                                required
                                className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-4">
                          <div className="flex flex-col gap-4 md:flex-row ">
                            <div className="lg:w-1/2">
                              <label htmlFor="Company">Company</label>
                              <input
                                type="text"
                                name="Company"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                placeholder="Company"
                                required
                                className="w-full px-3 py-2 bg-gray-100 border rounded focus:outline-none focus:border-blue-400"
                              />
                            </div>
                            <div className="lg:w-1/2">
                              <label htmlFor="Telephone">Telephone Number</label>
                              <input
                                type="tel"
                                name="Telephone"
                                onChange={(e) => setTelephone(e.target.value)}
                                placeholder="Telephone Number"
                                required
                                className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full mb-4">
                          <label htmlFor="Profession">Professional Title</label>
                          <input
                            type="Profession"
                            name="Profession"
                            value={professional_title}
                            onChange={(e) =>
                              setProfessional_title(e.target.value)
                            }
                            placeholder="Professional Title"
                            required
                            className="w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                          />
                        </div>

                        <button
                          type="submit"
                          className="w-40 bg-custom-button font-bold text-white rounded py-2 hover:bg-blue-600 mt-4"
                        >
                          Save Changes
                        </button>
                      </form>
                    </div>
                  </div>
                </>
              )}
              
              {activeTab === "billing" && <Billing />}
              {activeTab === "password" && <Password />}
              {activeTab === "notification" && <Notification />}
              {activeTab === "order" && <Order />}
              {activeTab === "certificate" && <Certificate />}
              {activeTab === "email" && <Email />}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Profile;
