import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { fetchUserLocation } from "../../utility";
import { useIsMobile } from "../hooks/useIsMobile";
import MyCourse from "./MyCourse";
import UpcomingAgenda from "./Sections/UpcomingAgenda";

export default function DashBoardSection({ targetRef, type, schedule = [] }) {
  const [loading, setLoading] = useState(false);

  const mobile = useIsMobile();
  const [location, setLocation] = useState();
  const dates =
    schedule?.map((cur) => {
      return location === "NG" ? cur.time?.wat?.date : cur.time?.est?.date;
    }) || [];

  const loadUserLocation = async () => {
    const res = await fetchUserLocation();
    setLocation(res);
  };

  useEffect(() => {
    loadUserLocation();
  }, []);

  return (
    <div
      style={{
        minHeight: "70vh",
        background: "#fff",
        padding: mobile ? "20px 0px" : "20px 0px",
      }}
    >
      <div className="innersection">
        <h1
          style={{
            fontSize: 30,
            marginBottom: 20,
            fontWeight: 500,
            color: "#000",
          }}
        >
          Schedules
        </h1>

        <div
          style={{
            display: "flex",
            //   alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%",
            gap: mobile ? 20 : 0,
          }}
        >
          {" "}
          <div
            style={{
              width: mobile ? "100%" : "58%",
              background: "#f1f1f1",
              borderRadius: 10,
              padding: mobile ? 15 : 25,
              height: 400,
              color: "#000",
              overflowY: "scroll",
            }}
          >
            <h1
              style={{
                fontWeight: 500,
                fontSize: 23,
              }}
            >
              Upcoming Agenda
            </h1>
            <div
              style={{
                position: "relative",
                marginTop: 20,
              }}
            >
              {schedule?.length > 0 ? (
                <>
                  {schedule?.map((cur, i) => (
                    <UpcomingAgenda {...cur} key={i} location={location} />
                  ))}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginBottom: 20,
                    alignItems: "center",
                    height: 300,
                  }}
                >
                  <h1>No UpComing Events</h1>
                  <p>Upcoming events will show here</p>
                </div>
              )}

              {/* <UpcomingAgenda /> */}
              {/* <UpcomingAgenda />
            <UpcomingAgenda />
            <UpcomingAgenda />
            <UpcomingAgenda />
            <UpcomingAgenda /> */}
            </div>
          </div>
          <div
            style={{
              width: mobile ? "100%" : "40%",
              background: "#f1f1f1",
              borderRadius: 10,
              padding: 25,
            }}
          >
            <Calendar
              // onClickDay={(value)=>{
              //   const filteredData = schedule?.filter(item => {
              //     const startTime = new Date(item.time?.wat).ge;
              //     const targetDate = new Date(value);
              //     console.log(startTime,targetDate)
              //     return startTime.getTime() === targetDate.getTime();
              //   });
              //   console.log(filteredData);
              // }}
              value={dates}

              // value={[new Date(), "2024-05-27T12:00:00"]}
            />
          </div>
        </div>
        <h1
          style={{
            fontSize: 30,
            marginBottom: 20,
            marginTop: 20,
            fontWeight: 500,
            color: "#000",
          }}
        >
          My Courses
        </h1>
        <div className="relative w-full flex items-center before:absolute before:w-[4.5%] before:left-0 before:h-[3px] before:bg-[#d15242] after:absolute after:bg-[#fff] after:h-[1px] after:w-[94%] after:right-0 mb-[2%]"></div>
        <div className="block">
          <MyCourse type={type} targetRef={targetRef} />
        </div>
      </div>
    </div>
  );
}
