import axios from "axios";

export const config = {
  baseURL: "https://e1xstaging.edoubleone.com/api/", //staging server
  // baseURL: "https://e1xstaging.edoubleone.com/api/", //staging server

  CalendlyUrl: "https://calendly.com/eedoubleone",
};

const client = axios.create({
  baseURL: config.baseURL,
  timeout: 100000,
  headers: {
    common: {
      "ngrok-skip-browser-warning": true,
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  },
});

export default client;
