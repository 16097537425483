import React, { useState } from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import Animator from "../../Aminator/Animator";

export default function FaqCard({ question, answer, index }) {
  const mobile = useIsMobile();
  const [showDetails, setShowDetails] = useState(false);
  return (
    <Animator
      delay={index === 0 ? 400 : index === 1 ? 480 : index === 2 ? 550 : 600}
      tindexmeout={
        index === 0 ? 400 : index === 1 ? 480 : index === 2 ? 550 : 600
      }
      variant="zoom"
      direction="left"
    >
      <div
        style={{
          padding: "30px 30px",
          border: `1px solid ${showDetails ? "#d15424" : "#6F6F6FAB"}`,
          height: showDetails ? "" : mobile ? 100 : 90,

          borderRadius: 20,
          marginBottom: 40,
          width: mobile ? "100%" : "49%",
          background: showDetails ? "#d15424" : "transparent",
          color: showDetails ? "#fff" : "#000",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              fontWeight: 500,
            }}
          >
            {question}
          </h1>
          <button
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          >
            {showDetails ? (
              <svg
                clip-rule="evenodd"
                width="26"
                height="26"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm4.253 7.75h-8.5c-.414 0-.75.336-.75.75s.336.75.75.75h8.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 24 24"
                color="blue"
              >
                <path
                  d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z"
                  fill="#d15424"
                />
              </svg>
            )}
          </button>
        </div>
        {showDetails && (
          <p
            style={{
              marginTop: 40,
            }}
          >
            {answer}
          </p>
        )}
      </div>
    </Animator>
  );
}
