import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import { toast } from "react-toastify";
import client from "../../api/client";
import CircularProgress from "../Generic/CircularProgress";
import { useIsMobile } from "../hooks/useIsMobile";
import RatingContent from "./Rating/RatingContent";
export default function Reviews({ course }) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [reviewData, setReviewData] = useState([]);
  const mobile = useIsMobile();
  const courseId = course?.course_id;

  const FetchAllReviews = async () => {
    try {
      const res = (await client.get(`/reviews/course/${courseId}`)).data;

      setReviewData(res);
    } catch (error) {
      console.log(error?.response);
    }
  };
  useEffect(() => {
    if (courseId) {
      FetchAllReviews();
    }
  }, [courseId]);

  const sendMessage = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await client.post("/reviews/add", {
        course_id: courseId,
        comment: message,
        stars: rating,
      });

      if (response) {
        setMessage("");
        setRating(0);
        FetchAllReviews();
      }

      toast.success("Review added successfully");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.msg);
    }

    // Clear the message input

    setLoading(false); //
  };

  return (
    <div
      style={{
        minHeight: 300,
        padding: mobile ? 15 : 30,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {[...reviewData]
          ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          ?.map((cur, i) => (
            <div
              key={i}
              style={{
                width: mobile ? "100%" : "49%",
              }}
            >
              <RatingContent {...cur} />
            </div>
          ))}
      </div>
      <div
        style={{
          width: "100%",
          height: 2,
          background: "#000",
          marginTop: 20,
          marginBottom: 20,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            marginBottom: 10,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Review This Course
        </h1>
        <textarea
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder="Message"
          style={{
            width: "100%",
            height: 100,
            padding: 10,
            borderRadius: 10,
            border: "1px solid #ccc",
            background: "transparent",
            marginBottom: 10,
          }}
        />
        <h1
          style={{
            marginBottom: 10,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Rate This Course
        </h1>
        <StarRatings
          starDimension="35px"
          starSpacing="10px"
          rating={rating}
          // rating={this.state.rating}
          starRatedColor="#f90"
          changeRating={(e) => {
            setRating(e);
          }}
          numberOfStars={5}
          name="rating"
        />
        <button
          disabled={message === "" || loading === true}
          onClick={sendMessage}
          style={{
            // width: mobile ? "100%" : "20%",
            marginTop: 50,
          }}
          className="
                    w-full sm:w-auto
                    rounded
                    text-xs sm:text-sm md:text-lg font-bold text-white
                    px-4 sm:px-4 md:px-6 py-4 sm:py-6 md:py-4
                    bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#d15242_50%] to-[#2c75e4_50%]
                    transition-all delay-0 duration-300 ease-out cursor-pointer
                  "
        >
          {loading ? <CircularProgress /> : "SEND REVIEW"}
        </button>
      </div>
    </div>
  );
}
