import React, { useRef, useEffect, useContext, useState } from "react";
import Player from "@vimeo/player";
import { GlobalContext } from "../../context";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { database } from "../../firebase";
import { extractIdFromUrl } from "../../utility";
import useInterval from "../../components/Generic/useInterval"
export default function VimeoPlayer({ uuid, lessonId, videoUrl }) {
const [isloaded,setIsloaded]= useState(false)
  const [videoId, setVideoId] = useState("");
  useEffect(() => {
    if (videoUrl) {
       const id = extractIdFromUrl(videoUrl);
       setVideoId(id);
    }
   
  }, [videoUrl]);
  const [player, setPlayer] = useState();
  const [lessonProgress, setLessonProgress] = useState();
  const {
    authState: { data },
  } = useContext(GlobalContext);
  const user = data?.user;

  const iframeRef = useRef(null);

  const fetchLessonProgress = async (lessonId, player) => {
    if (!lessonId) {
      console.error("No lessonId provided");
      return;
    }

    const userId = user?.id?.toString();
    if (!userId) {
      console.error("No user id available");
      return;
    }

    try {
      const docRef = doc(database, userId, lessonId.toString());
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setLessonProgress(docSnap.data());
        if (docSnap.data()?.video_timestamp) {
          setplayerProgress(player, docSnap.data()?.video_timestamp);
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching lesson progress:", error);
    }
  };
  const getUserWatchProgress = ()=> {
  player
    .getCurrentTime()
    .then(function (seconds) {
       saveUserVideoProgress(seconds);
   
      // seconds = the current playback position
    })
    .catch(function (error) {
      // an error occurred
    });
}
  useEffect(() => {
    const player = new Player(iframeRef.current);
    setPlayer(player);
   

    
  }, [videoId,]);
  

  useEffect(() => {
    fetchLessonProgress(lessonId, player);
  }, [videoId,  player,]);

  const setplayerProgress = (player, time) => {

    player
      .setCurrentTime(time)
      .then(function (seconds) {
        console.log("seconds", seconds);
        // console.log("seconds:", seconds);
        // seconds = the actual time that the player seeked to
      })
      .catch(function (error) {});
  };
  const saveUserVideoProgress = async (progress) => {
    try {
      const id = user.id?.toString();

      const res = await setDoc(doc(database, id, lessonId?.toString()), {
        user_id: user.id,
        course_id: uuid,
        lesson_id: lessonId,
        video_timestamp: progress,
      });
    } catch (error) {
      //   console.log("error saving user video time", error);
    }
  };
useInterval(getUserWatchProgress,1000)
  return (
    <div
      style={{
        width: "100%",
      }}
    >
          <iframe
        onLoad={() => {
    
              fetchLessonProgress(lessonId,player)
        }}
   
        ref={iframeRef}
        src={`https://player.vimeo.com/video/${videoId}?h=68311fb71a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        // style="position:absolute;top:0;left:0;width:100%;height:100%;"
        className="w-full min-h-[60vh]"
        title="Week Three"
      />
    </div>
  );
}
