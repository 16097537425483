import client from "../../api/client";

export const FetchallOnlineCousrse = async (dispatch) => {
  dispatch({
    type: "LOADING",
  });

  try {
    const res = (await client.get("/courses/online/online-courses/all")).data;

    dispatch({
      type: "FETCHED_DATA",
      payload: res,
    });
    return true;
  } catch (error) {
    console.log("error fetching all courses", error.response);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
    return false;
  }
};
export const FetchUserCommunityChatGroup = async (dispatch) => {
  dispatch({
    type: "LOADING",
  });
  try {
    const res = (await client.get("/community/cohorts")).data;

    dispatch({
      type: "FETCHED_DATA",
      payload: res,
    });
    return true;
  } catch (error) {
    console.log("error fetching all community", error.response);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
    return false;
  }
};
export const FetchallLiveCousrse = async (dispatch) => {
  dispatch({
    type: "LOADING",
  });
  try {
    const res = (await client.get("/courses/live/live-courses/all")).data;
    dispatch({
      type: "FETCHED_DATA",
      payload: res,
    });
    return true;
  } catch (error) {
    console.log("error fetching all courses", error.response);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
    return false;
  }
};
export const FetchCoursesCategory = async (dispatch) => {
  dispatch({
    type: "LOADING",
  });
  try {
    const res = await client.get("v2/categories");

    dispatch({
      type: "FETCHED_DATA",
      payload: res?.data,
    });
  } catch (error) {
    console.log("error fetching user courses", error.response);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
  }
};
export const loadSchedule = async (dispatch) => {
  dispatch({
    type: "LOADING",
  });
  try {
    const res = (await client.get("/subscribers/myschedules")).data;

    dispatch({
      type: "FETCHED_DATA",
      payload: res,
    });
  } catch (error) {
    console.log(error?.response);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
  }
};
export const FetchUserCoureses = async (dispatch) => {
  dispatch({
    type: "LOADING",
  });
  try {
    const res = (await client.get("v2/mycourses")).data;

    dispatch({
      type: "FETCHED_DATA",
      payload: res?.data,
    });
  } catch (error) {
    console.log("error fetching user courses", error.response);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
  }
};
export const ActivateCourse = async (data, dispatch) => {
  try {
    const res = (await client.post("/subscribers/add", data)).data;
    if (res) {
      FetchUserCoureses(dispatch);
      return true;
    }
  } catch (error) {
    console.log("error activating course", error.response);

    return false;
  }
};
