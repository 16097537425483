import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Callback = () => {
  const { reference } = useParams();

  useEffect(() => {
    // Fetch the verification from your payment service
    fetch(`http://localhost:3000/verifyTransaction/${reference}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          // Assuming 'data' contains the payment information
          // Now prepare the data for sending to the subscribers API
          const postData = {
            subscriptionId: data.reference,
            email: data.customer.email,
            date: new Date().toISOString(), // or use the date from 'data' if available
            paymentMethod: data.paymentMethod,
          };

          // Send a POST request to the subscribers API
          fetch("https://e1x.nueoffshore.com/api/subscribers/subscribers/all", {
            method: "POST", // Verify the method
            headers: {
              "Content-Type": "application/json",
              // Include any necessary headers, like an authorization token
            },
            body: JSON.stringify(postData),
          })
            .then((response) => response.json())
            .then((apiData) => {})
            .catch((error) => {
              console.error("Error sending data to subscribers API:", error);
            });
        } else {
          // Handle the failure case
          console.error("Payment verification failed", data);
        }
      })
      .catch((error) => {
        console.error("Error verifying transaction:", error);
      });
  }, [reference]);

  return <div>Verifying your payment...</div>;
};

export default Callback;
