import React from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import SponsorComment from "./SponsorComment";
import { Slide } from "react-slideshow-image";
import { data } from "autoprefixer";

export default function SponsorScreen({ data }) {
  const mobile = useIsMobile();
  return (
    <div
      style={{
        background: "#fff",
        padding: mobile ? "40px 0px" : "40px 0px",
        minHeight: "70vh",
      }}
    >
      <div
        style={{
          background: "#FFEDE7",
          minHeight: "70vh",
          width: "100%",
          padding: mobile ? "40px 15px" : 80,
          borderRadius: 30,
        }}
      >
        <p
          style={{
            color: "#D15424",
          }}
        >
          Sponsors list
        </p>
        <h1
          style={{
            fontSize: 25,
            fontWeight: 500,
          }}
        >
          Meet our Sponsors
        </h1>
        <div
          style={{
            marginTop: 80,
            height: "100%",
            // width: "100%",
          }}
        >
          <SponsorComment {...data} />
        </div>
      </div>
    </div>
  );
}
