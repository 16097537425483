import { Grid } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../../assets/images/categories1.png";
import IsEmpty from "../../components/Generic/isEmpty";
import { useIsMobile } from "../../components/hooks/useIsMobile";
import LoadingSpinner from "../../components/LoadingSpinner";
import { GlobalContext } from "../../context";
import MainLayouts from "../../layout/MainLayouts";
function Categories() {
  const {
    categoryState: { data, loading },
  } = useContext(GlobalContext);

  const mobile = useIsMobile();
  const navigation = useNavigate();
  return (
    <MainLayouts>
      <div
        className=" pt-[27%] md:pt-[9.1%]  bg-[#f1f1f1] px-[0%] md:px-[7%]   pb-[27%]  md:pb-[9.1%]   "
        style={{
          minHeight: "90vh",
          overflowX: "hidden",

          borderBottomWidth: 2,
        }}
      >
        {" "}
        <div className="innersection">
          <header
            className="flex min-h-[200px] mb-11 mt-2 text-white  justify-between items-center bg-[#D15424] p-7 rounded-xl relative"
            style={{
              flexDirection: "row",
              width: "100%",
            }}
          >
            <div
              style={{
                width: mobile ? "100%" : "40%",
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: 600,
                }}
              >
                Courses Categories
              </h1>
              <p className="mt-3">
                Here is the categories of all the courses we offer @E1X Academy
              </p>
            </div>
            {!mobile && (
              <img
                className="bouncing-image"
                src={Image}
                style={{
                  height: 280,
                  width: 300,
                  objectFit: "contain",
                  position: "absolute",
                  right: 20,
                }}
              />
            )}
          </header>
          {data?.length > 0 ? (
            <Grid container spacing={mobile ? 2 : 6}>
              {data?.map((cur, i) => (
                <Grid item md={4} xs={12} key={i}>
                  <div
                    className="shadow-md "
                    style={{
                      padding: 20,
                      background: "#f5f5f5",
                      width: "100%",
                      borderRadius: 20,
                    }}
                  >
                    <h1
                      style={{
                        fontSize: 22,
                        fontWeight: 600,
                        marginBottom: 5,
                      }}
                    >
                      {cur.name}
                    </h1>
                    <p
                      style={{
                        marginBottom: 30,
                      }}
                    >
                      {cur.description}
                    </p>
                    <button
                      onClick={() => {
                        navigation(`/all-courses/${cur.id}`);
                      }}
                      style={{
                        borderColor: "#d15242",
                        borderWidth: 1,
                        color: "#000",
                        width: "100%",
                        borderRadius: 20,
                      }}
                      className="h-4  text-white py-5 px-6 md:px-10  rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
                    >
                      See All Courses
                    </button>
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : loading ? (
            <LoadingSpinner />
          ) : (
            <IsEmpty />
          )}
        </div>
      </div>
    </MainLayouts>
  );
}
export default Categories;
