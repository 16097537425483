import React, { useState } from "react";
import background from "../../../assets/images/sectorsection1bg.jpeg";

import { useIsMobile } from "../../hooks/useIsMobile";
import TextField from "../../Generic/TextField";
import { uploadHelmper } from "../../../uploadCDN";
import client from "../../../api/client";
import { toast } from "react-toastify";
import { checkForEmptyFields } from "../../../utility";
import CircularProgress from "../../Generic/CircularProgress";
import { useNavigate } from "react-router-dom";

export default function SponsorForm() {
  const mobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const navigation = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    organization: "",

    message: "",

    sponsorship_details: "",
  });
  const [file, setFile] = useState(null);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const HandleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await client.post("/sponsor", formData);
      toast.success(
        "Successfully created a sponsorship A mail will be sent to to your gmail account"
      );
      navigation("/sponsor");
    } catch (error) {
      console.log("error uploading sponsorship", error);
    }
    setLoading(false);
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const sponsorshipOptions = [
    { id: "Platinum-NGN 1,000,000", label: "Platinum", price: "NGN 1,000,000" },
    { id: "Gold-NGN 500,000", label: "Gold", price: "NGN 500,000" },
    { id: "Silver-NGN 300,000", label: "Silver", price: "NGN 300,000" },
  ];

  const CameraIcon = () => (
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 14.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm-6-5a9 9 0 1118 0 9 9 0 01-18 0zm0 0L3 5.7m18 0L21 10m-9-4.5L15.5 7m0 0L12 10.5m3.5-3.5l2.5-1.8M6.5 7L3 5.7m3.5 1.3L8 10.5"
      />
    </svg>
  );

  return (
    <div
      style={{
        minHeight: "100vh",

        position: "relative",
        backgroundImage: `url(${background})`, // Replace with your image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#273044DE",

          padding: mobile ? "50px 0px" : "100px 0px",
          width: "100%",
        }}
      >
        <div
          className="innersection"
          style={{
            background: "#D9D9D91A",
            padding: mobile ? 15 : "80px 80px",
            width: "100%",
            minHeight: "100vh",
            borderRadius: 20,
            border: "0.3px solid #fff",
            color: "#fff",
          }}
        >
          <h1
            style={{
              fontSize: 30,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            Active scholarships
          </h1>

          <div
            style={{
              marginTop: 50,
            }}
          >
            <form onSubmit={HandleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: mobile ? "column" : "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: mobile ? 30 : 50,
                  gap: mobile && 30,
                }}
              >
                <TextField
                  required
                  label={"First Name"}
                  value={formData.first_name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      first_name: e.target.value,
                    });
                  }}
                  placeholder={"First Name"}
                  width={mobile ? "100%" : "48%"}
                />
                <TextField
                  required
                  label={"Last Name"}
                  value={formData.last_name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      last_name: e.target.value,
                    });
                  }}
                  placeholder={"Last Name"}
                  width={mobile ? "100%" : "48%"}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: mobile ? "column" : "row",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: mobile ? 30 : 50,
                  gap: mobile && 30,
                }}
              >
                <TextField
                  required
                  label={"Email Address"}
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                  placeholder={"Email Address"}
                  width={mobile ? "100%" : "48%"}
                />
                <TextField
                  required
                  value={formData.phone}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      phone: e.target.value,
                    });
                  }}
                  label={"Phone Number"}
                  placeholder={"Phone Number"}
                  width={mobile ? "100%" : "48%"}
                />
              </div>
              <div
                style={{
                  marginBottom: mobile ? 30 : 50,
                }}
              >
                <TextField
                  required
                  value={formData.organization}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      organization: e.target.value,
                    });
                  }}
                  label={"Organization"}
                  placeholder={"Organization"}
                  width="100%"
                />
              </div>
              <div
                style={{
                  marginBottom: mobile ? 30 : 50,
                }}
              ></div>
              <div
                style={{
                  marginBottom: mobile ? 30 : 50,
                }}
              >
                <TextField
                  required
                  value={formData.sponsorship_details}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      sponsorship_details: e.target.value,
                    });
                  }}
                  label={"Sponsor Details or information"}
                  placeholder={"Your Message to us"}
                  height={200}
                  multiple={true}
                />
              </div>
              <div>
                <TextField
                  value={formData.message}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      message: e.target.value,
                    });
                  }}
                  label={"Your Message to us"}
                  placeholder={"Your Message to us"}
                  height={200}
                  multiple={true}
                />
              </div>
              <div
                style={{
                  marginTop: 50,
                }}
              >
                <button
                  type="submit"
                  style={{
                    background: "#D15424",
                    height: 54,
                    borderRadius: 30,
                    width: "100%",
                  }}
                >
                  {loading ? <CircularProgress /> : "Send"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
