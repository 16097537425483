import { Avatar, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { GlobalContext } from "../../context";

function ChatLeft({ data = [], onClick = (e) => {}, value }) {
  const {
    communityState: { loading },
  } = useContext(GlobalContext);

  return (
    <div
      style={{
        marginTop: 23,
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "#d15424",
            }}
          />
        </div>
      ) : (
        data?.map((cur, i) => (
          <div
            key={i}
            onClick={() => {
              onClick({
                id: cur.id,
                name: cur.name,
              });
            }}
            style={{
              display: "flex",
              padding: "12px 25px",
              background: cur.id === value && "#222",
              cursor: "pointer",
              // alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 15,
                width: "80%",
              }}
            >
              <Avatar
                style={{
                  width: 55,
                  height: 55,
                }}
              />
              <div>
                <h1 style={{ fontWeight: 600 }}>{cur.name}</h1>
                <p
                  style={{
                    fontSize: 13,
                  }}
                >
                  this is a test chat nothing more
                </p>
              </div>
            </div>

            <div
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
                textAlign: "end",
              }}
            >
              <p
                style={{
                  fontSize: 13,
                  color: "#d15242",
                }}
              >
                7:50 AM
              </p>
              <p
                style={{
                  color: "#fff",
                  fontSize: 10,
                  background: "#d15242",
                  borderRadius: 20,
                  padding: 5,
                }}
              >
                20
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
export default ChatLeft;
