import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadAnswers from "../../../page/Assignments/UploadAnswers";
import { isDateValid } from "../../../utility";
import { useIsMobile } from "../../hooks/useIsMobile";
export default function AssignmentComponent({ data }) {
  const tabs = data && Object.keys(data);
  const [selectedTab, setselectedTab] = useState(tabs[0]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  // Usage example

  // Usage example

  const mobile = useIsMobile();
  useEffect(() => {
    setselectedTab(tabs[0]);
  }, [data]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        fullWidth
      >
        <UploadAnswers
          id={id}
          onClose={() => {
            setOpen(false);
          }}
        />
      </Dialog>
      {tabs?.length > 1 && (
        <nav className="flex mb-[5%] overflow-auto">
          {tabs?.map((cur, i) => (
            <span
              key={i}
              className={
                cur === selectedTab
                  ? "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
                  : "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
              }
              onClick={() => setselectedTab(cur)}
            >
              {cur}
            </span>
          ))}
        </nav>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 20,

          minHeight: 300,
          padding: mobile ? 15 : 30,
        }}
      >
        {data && data[selectedTab]?.length > 0 ? (
          data &&
          data[selectedTab]?.map((cur, i) => (
            <div
              style={{
                width: mobile ? "100%" : "49%",
                minHeight: 80,
                maxHeight: 150,
                background: "#fff",
                //   width: "100%",
                marginBottom: 20,
                borderRadius: 10,
                padding: 15,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                // flexWrap: "wrap",
                // alignItems: "center",
              }}
              key={i}
            >
              <div
                style={{
                  width: "60%",
                }}
              >
                <h1
                  style={{
                    fontSize: 21,
                    fontWeight: 500,
                    marginBottom: 5,
                    lineBreak: "anywhere",
                  }}
                >
                  {cur.title}
                </h1>
                <p
                  style={{
                    color: "#aaa",
                  }}
                >
                  {cur.description}
                </p>
                {/* <p
                  style={{
                    marginBottom: 2,
                    color: "#aaa",
                  }}
                >
                  {" "}
                  Date : {format(new Date(cur.start_time || ""), "dd-MM-yyyy ")}
                </p>
                <p
                  style={{
                    color: "#aaa",
                  }}
                >
                  {" "}
                  Time : {format(new Date(cur.start_time || ""), "HH:mm a")}
                </p> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <a
                  href={cur.problem_download_link}
                  _blank
                  style={{
                    width: "49%",
                  }}
                >
                  <button
                    style={{
                      background: "#d15242",
                      padding: "10px 10px",
                      borderRadius: 5,
                      width: "100%",
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: mobile ? 10 : 12,
                      cursor: "pointer",
                    }}
                  >
                    Download Assignment
                  </button>
                </a>
                {isDateValid(cur.solution_release_at) ? (
                  <a
                    href={cur.solution_download_link}
                    _blank
                    style={{
                      width: "49%",
                    }}
                  >
                    {" "}
                    <button
                      style={{
                        background: cur.solution_download_link
                          ? "#d15242"
                          : "#aaa",
                        padding: "10px 10px",
                        borderRadius: 5,
                        width: "100%",
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: mobile ? 10 : 12,
                        cursor: cur.solution_download_link
                          ? "pointer"
                          : "default",
                      }}
                    >
                      Download Solution
                    </button>
                  </a>
                ) : (
                  <button
                    onClick={() => {
                      setOpen(true);
                      setId(cur.id);
                    }}
                    style={{
                      background: cur.solution_download_link
                        ? "#d15242"
                        : "#aaa",
                      width: "49%",
                      padding: "10px 10px",
                      borderRadius: 5,
                      // width: "100%",
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: mobile ? 10 : 12,
                      cursor: cur.solution_download_link
                        ? "pointer"
                        : "default",
                    }}
                  >
                    Submit Solution
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            <p>No Assignment Avaliable</p>
          </div>
        )}
      </div>
    </div>
  );
}
