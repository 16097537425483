import React, { useContext, useEffect, useState } from "react";
import { PiPlayCircleFill } from "react-icons/pi";
import {
  convertTimeToVideoTimeStamp,
  convertVideoTimeStampToPercent,
} from "../../../utility";
import { useNavigate, useParams, Link } from "react-router-dom";
import { UserContext } from "../../../services/UserContext";
import { doc, onSnapshot } from "firebase/firestore";
import { database } from "../../../firebase";
import { GlobalContext } from "../../../context";
export default function CourseMenu({
  uuid,
  duration,
  title,
  change,
  onClick = () => {},
  course_id,
  lessonId,
  selectedLessonIndex,
  index,
}) {
  const param = useParams();

  const { authState } = useContext(GlobalContext);
  const user = authState?.data?.user;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const fetchLessonProgress = async () => {
    setLoading(true);
    try {
      const unsub = onSnapshot(
        doc(database, user?.id?.toString(), uuid?.toString()),
        (doc) => {
          setData(doc.data()?.video_timestamp);
        }
      );

      //   setData(response.data?.
      //     video_timestamp
      //     );
    } catch (error) {
      console.error("Error fetching lesson progress:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLessonProgress();
  }, [uuid]);

  return (
    <div
      key={uuid}
      className={`p-4 hover:bg-[#f7f7f7] transition-colors delay-0 duration-100 ease-out border-b border-gray-200 cursor-pointer ${
        selectedLessonIndex === index ? "bg-[#aaa]" : "bg-white"
      }`}
      onClick={() => onClick()}
    >
      <div className="flex items-center justify-between group">
        <div className="">
          <p className="text-sm">
            <span className="font-bold text-[#333333]">Video: </span>
            {title}
          </p>
          <p className="text-xs text-gray-500">{duration}</p>
          {data && (
            <div className="w-full bg-gray-200 rounded h-2.5 dark:bg-gray-700 mt-4">
              <div
                className="bg-blue-600 h-2.5 rounded"
                style={{
                  backgroundColor: "#d15424",
                  borderRadius: 20,
                  width: `${convertVideoTimeStampToPercent(
                    data,
                    convertTimeToVideoTimeStamp(duration)
                  )}%`,
                }}
              ></div>
            </div>
          )}
        </div>
        {data &&
          convertVideoTimeStampToPercent(
            data,
            convertTimeToVideoTimeStamp(duration)
          ) + "%"}
        <div
          className={`text-lg group-hover:text-[#d15424] transition-colors delay-0 duration-100 ease-out ${
            selectedLessonIndex === index ? "text-[#d15424]" : "text-gray-300"
          }`}
        >
          <PiPlayCircleFill />
        </div>
      </div>
    </div>
  );
}
