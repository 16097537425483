import React from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import FaqCard from "./FAQ/FaqCard";

export default function SonsorFaq() {
  const mobile = useIsMobile();
  return (
    <div
      style={{
        background: "#f1f1f1",
        padding: mobile ? "40px 0px" : "100px 0px",
      }}
    >
      <div
        className="innersection"
        style={{
          minHeight: "70vh",
        }}
      >
        <div
          style={{
            width: mobile ? "100%" : "40%",
          }}
        >
          <p
            style={{
              color: "#D15424",
              marginBottom: 10,
            }}
          >
            More Information
          </p>
          <h1
            style={{
              fontSize: 25,
              fontWeight: 500,
            }}
          >
            Frequently asked questions (FAQ)
          </h1>
          <p
            style={{
              marginTop: 10,
            }}
          >
            Feeling stuck? Rest assured, we've got you covered with all the help
            you need. Get your questions answered one place.
          </p>
        </div>
        <div
          style={{
            marginTop: 50,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            overflowY: "auto",
          }}
        >
          {[
            {
              question: "Why should I become a sponsor?",
              answer:
                "By becoming a sponsor, you make a direct and meaningful impact on the lives of deserving individuals. You help shape a brighter future by providing them with the opportunity to reach their full potential.",
            },
            {
              question: "How does the sponsorship process work?",
              answer:
                "Sponsorship is simple and customizable! You can:\n\n- Create a named scholarship: Leave your legacy and promote your values.\n- Sponsor anonymously: Focus on the impact, not the recognition.\n- Set your giving criteria: Target specific areas of need or demographics.\n- Select recipients: Be personally involved in the choice (if you'd like).\n- Optionally meet scholarship recipients: Build a connection.",
            },
            {
              question: "What level of control do I have over my sponsorship?",
              answer:
                "You have a significant amount of control over your sponsorship experience. Choose how visible you want to be, set the criteria for your scholarship, and decide how involved you'd like to be in the selection process.",
            },
            {
              question: "Can I track the impact of my sponsorship?",
              answer:
                "Yes! We believe in full transparency. You'll receive regular updates on the progress of your scholarship recipients, so you can see the tangible difference your contribution is making.",
            },
            {
              question: "Are there different sponsorship levels?",
              answer:
                "Yes, we offer a range of sponsorship levels to accommodate different budgets and goals. Contact us to discuss options tailored to your needs.",
            },
            {
              question: "How do I get started?",
              answer:
                "The first step is easy! Use the 'Start Here' button on this page or contact us directly to begin your sponsorship journey.",
            },
          ]?.map((cur, i) => (
            <FaqCard key={i} {...cur} index={i} />
          ))}
        </div>
      </div>
    </div>
  );
}
