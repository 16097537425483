import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { LiveCourseDetailContext } from "../../services/LiveCourseDetail";

import { motion, useAnimation } from "framer-motion";
import { BiCheckCircle } from "react-icons/bi";

import { useInView } from "react-intersection-observer";

const Description = () => {
  const { uuid } = useParams();

  const { courses, fetchLiveCourseDetails } = useContext(
    LiveCourseDetailContext
  );

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // const { course_description, images, instructors } = courses;

  useEffect(() => {
    if (uuid) {
      fetchLiveCourseDetails(uuid);
    }
  }, []);

  const [animateImage, setAnimateImage] = useState(false);
  const [activeTab, setActiveTab] = useState("description");
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      });
      setAnimateImage(true);
    }
  }, [controls, inView]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="w-full custom-description-background  mx-auto  ">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center space-x-10 justify-center py-5 p-4">
          <button
            className={`font-bold border-b-4 border-transparent hover:border-[#e46601] pb-3 focus:outline-none ${
              activeTab === "description" ? "border-[#e46601]" : ""
            }`}
            onClick={() => handleTabChange("description")}
          >
            Overview
          </button>
          <button
            className={`font-bold border-b-4 border-transparent hover:border-[#e46601] pb-3 focus:outline-none ${
              activeTab === "content" ? "border-[#e46601]" : ""
            }`}
            onClick={() => handleTabChange("content")}
          >
            Course Content
          </button>
          {/* <button
            className={`font-bold border-b-4 border-transparent hover:border-[#e46601] pb-3 focus:outline-none ${
              activeTab === " instructor" ? "border-[#e46601]" : ""
            }`}
            onClick={() => handleTabChange("instructor")}
          >
            Instructor
          </button> */}
          {/* <button
            className={`font-bold border-b-4 border-transparent hover:border-[#e46601] pb-3 focus:outline-none ${
              activeTab === "reviews" ? "border-[#e46601]" : ""
            }`}
            onClick={() => handleTabChange("reviews")}
          >
            Reviews
          </button> */}
        </div>
      </div>
      <div className="border-t pb-6"> </div>
      {activeTab === "description" && (
        <div className="max-w-5xl mx-auto space-y-5 py-12 p-4">
          <p className="font-bold text-2xl">Description</p>
          <div className="space-y-5">
            {showFullDescription || !courses?.course_description ? (
              <p>
                {courses?.course_description ||
                  "They perform quantitative analysis to identify trends, patterns, and anomalies in financial data to support strategic planning and investment decisions. Strong analytical skills, proficiency in financial modeling, and a solid understanding of financial markets are essential for success in this role."}
              </p>
            ) : (
              <p>
                {courses.course_description.slice(0, 150)}
                {"... "}
                <span
                  className="text-[#e46601] cursor-pointer"
                  onClick={toggleDescription}
                >
                  Show More
                </span>
              </p>
            )}
          </div>
          {showFullDescription && (
            <p
              className="border-b border-[#e46601] w-[80px] text-[#e46601] cursor-pointer"
              onClick={toggleDescription}
            >
              Show Less
            </p>
          )}

          <div className="mt-10">
            <p className="font-bold text-lg">Program Features:</p>
            <div className="mt-2">
              {courses?.program_features?.split("\n").map((content, index) => (
                <div key={index} className="flex items-center gap-2">
                  <BiCheckCircle />
                  <p>{content}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-10">
            <p className="font-bold text-lg">Target Audience:</p>
            <div className="mt-2">
              {courses?.program_target?.split("\n").map((content, index) => (
                <div key={index} className="flex items-center gap-2">
                  <BiCheckCircle />
                  <p>{content}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-10">
            <p className="font-bold text-lg">Program Prerequisite:</p>
            <div className="mt-2">
              {courses?.program_prerequisite
                ?.split("\n")
                .map((content, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <BiCheckCircle />
                    <p>{content}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {activeTab === "content" && (
        <div className="max-w-4xl mx-auto space-y-5 py-12">
          <p className="font-bold text-2xl ml-8">Courses Content</p>
          <div className="flex flex-col md:flex-row items-center gap-5">
            {/* First column with two items */}
            <div className="w-full md:w-1/2 space-y-2">
              {courses?.course_content
                ?.split("\n")
                .slice(0, 4)
                .map((content, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <BiCheckCircle />
                    <p>{content}</p>
                  </div>
                ))}
            </div>
            {/* Second column with two items */}
            <div className="w-full md:w-1/2 space-y-2">
              {courses?.course_content
                ?.split("\n")
                .slice(4, 8)
                .map((content, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <BiCheckCircle />
                    <p>{content}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}

      {/* {activeTab === "instructor" && (
        <div className="max-w-5xl mx-auto space-y-5  py-12 p-4">
          <p className="font-bold text-2xl">Instructor </p>
          <div className="space-y-5">
            {courses?.instructors?.map((instructor) => (
              <div>
                <p></p>
                <p>{instructor}</p>
              </div>
            ))}

            <div className="flex gap-8">
              {courses?.images?.map((img) => (
                <div>
                  <img
                    key={img.id}
                    src={img}
                    alt="instructor"
                    className="w-24 h-24 rounded"
                  />
                </div>
              ))}

              <div className="space-y-3">
                <div className="flex items-center gap-2">
                  <FcRating />

                  <p className="text-sm font-semibold">4.6 Instructor Rating</p>
                </div>
                <div className="flex items-center gap-2">
                  <FaAward />
                  <p className="text-sm font-semibold ">114,464 Reviews</p>
                </div>
                <div className="flex items-center gap-2">
                  <BsPeopleFill />
                  <p className="text-sm font-semibold">1,152,522 Students</p>
                </div>
                <div className="flex items-center gap-2">
                  <GoDeviceCameraVideo />
                  <p className="text-sm font-semibold">1,152,522 Students</p>
                </div>
              </div>
            </div>

            <p>
              Al Sweigart is a software developer and author. He has written
              eight programming books, spoken at Python conferences, and has
              taught both kids and adults how to program. Python is his favorite
              programming language, and he is the developer of several open
              source modules for it. He is driven to make programming knowledge
              available to all, and his books freely available under a Creative
              Commons license.
            </p>
          </div>
        </div>
      )} */}
      {/* {activeTab === "reviews" && (
        <div className="max-w-4xl mx-auto space-y-5 py-12 p-4">
          <div className="lg:flex flex-row  gap-6">
            <div className="lg:w-1/2 w-full">
              <div className="background-card mb-4 px-4 py-10 space-y-8">
                <p className="text-xs">
                  The courses are well-structured, and the instructors are
                  knowledgeable and supportive. I've gained practical skills
                  that have already made a positive impact on my career. I
                  highly recommend Academy to anyone looking to advance their
                  education.
                </p>
                <div className="flex items-center justify-between border-b pb-1">
                  <div>
                    <p className="font-bold">Jessica Akoh</p>
                    <p className="text-xs">21 days ago</p>
                  </div>
                  <div>
                    <img src={StudentReviews} alt="student Reviews" />
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:w-1/2 w-full">
              <div className="background-card mb-4 px-4 py-10 space-y-8">
                <p className="text-xs">
                  I've been a student at Ex1 Academy for the past year, and I've
                  found the variety of courses to be impressive. The instructors
                  are engaging, and the online platform is user-friendly. The
                  only reason I didn't give it five stars is that I wish there
                  were more opportunities for hands-on projects.
                </p>
                <div className="flex items-center justify-between border-b pb-1">
                  <div>
                    <p className="font-bold">Jane Smith</p>
                    <p className="text-xs">21 days ago</p>
                  </div>
                  <div>
                    <img src={StudentReviews} alt="student Reviews" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Description;
