import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useIsMobile } from "../../hooks/useIsMobile";

export default function Recordings({ data }) {
  const tabs = data && Object.keys(data);
  const [selectedTab, setselectedTab] = useState(tabs[0]);

  const openVideoInNewTab = (video) => {
    const iframe = document.createElement("iframe");
    iframe.style.width = "100%";
    iframe.style.height = "100vh";
    iframe.style.border = "none";
    iframe.style.position = "fixed";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.zIndex = "9999"; // Ensures the iframe appears above other content

    const videoHtml = `
        <html>
          <head>
            <style>
              body {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                flex-direction: column;
              }
              .close-btn {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: #f44336;
                color: white;
                border: none;
                padding: 10px 20px;
                cursor: pointer;
                z-index: 10000;
                font-size: 16px;
                border-radius: 5px;
              }
              .close-btn:hover {
                background-color: #d32f2f;
              }
            </style>
          </head>
          <body>
            <button class="close-btn" onclick="window.parent.postMessage('close', '*')">Close</button>
            <video
              width="100%"
              controls
              controlsList="nodownload"
              disablePictureInPicture
              oncontextmenu="event.preventDefault();"
            >
              <source src="${video}" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </body>
        </html>
    `;

    // Write the videoHtml to the iframe's document
    document.body.appendChild(iframe);
    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(videoHtml);
    iframeDoc.close();

    // Add a listener to remove the iframe when the video ends
    iframeDoc.querySelector("video").addEventListener("ended", () => {
      document.body.removeChild(iframe);
    });

    // Add a listener to remove the iframe when the close button is clicked
    window.addEventListener("message", (event) => {
      if (event.data === "close") {
        document.body.removeChild(iframe);
      }
    });
  };

  // Usage example

  // Usage example

  const mobile = useIsMobile();
  useEffect(() => {
    setselectedTab(tabs[0]);
  }, [data]);
  return (
    <div>
      {tabs?.length > 1 && (
        <nav className="flex mb-[5%] overflow-auto">
          {tabs?.map((cur, i) => (
            <span
              key={i}
              className={
                cur === selectedTab
                  ? "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
                  : "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
              }
              onClick={() => setselectedTab(cur)}
            >
              {cur}
            </span>
          ))}
        </nav>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 20,

          minHeight: 300,
          padding: mobile ? 15 : 30,
        }}
      >
        {data && data[selectedTab]?.length > 0 ? (
          data &&
          data[selectedTab]?.map((cur, i) => (
            <div
              style={{
                width: mobile ? "100%" : "49%",
                minHeight: 100,
                maxHeight: 190,
                background: "#fff",
                //   width: "100%",
                marginBottom: 20,
                borderRadius: 10,
                padding: 15,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
              }}
              key={i}
            >
              <div
                style={{
                  width: "60%",
                }}
              >
                <h1
                  style={{
                    fontSize: 21,
                    fontWeight: 500,
                    marginBottom: 10,
                    lineBreak: "anywhere",
                  }}
                >
                  {cur.topic}
                </h1>
                <p
                  style={{
                    marginBottom: 2,
                    color: "#aaa",
                  }}
                >
                  {" "}
                  Date : {format(new Date(cur.start_time || ""), "dd-MM-yyyy ")}
                </p>
                <p
                  style={{
                    color: "#aaa",
                  }}
                >
                  {" "}
                  Time : {format(new Date(cur.start_time || ""), "HH:mm a")}
                </p>
              </div>
              <div>
                <button
                  onClick={() => {
                    openVideoInNewTab(cur.link);
                  }}
                  style={{
                    background: "#d15242",
                    padding: "10px 10px",
                    borderRadius: 5,
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                >
                  Watch
                </button>
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            <p>No Video Avaliable</p>
          </div>
        )}
      </div>
    </div>
  );
}
