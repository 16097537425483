import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../services/UserContext";
import axios from "axios";
import { GlobalContext } from "../context";
import mailicon from ".././assets/icons/mail.avif";
import { useIsMobile } from "./hooks/useIsMobile";
import client from "../api/client";
import { toast } from "react-toastify";
import CircularProgress from "./Generic/CircularProgress";
import MainLayouts from "../layout/MainLayouts";
function VerifyEmail() {
  const {
    authState: { data },
    authDispatch,
  } = useContext(GlobalContext);
  const user = data?.user;

  const [requestSent, setRequestSent] = useState(false);
  const [loading, setloading] = useState(false);

  const userId = user && user.id;

  const [isEmailResent, setEmailResent] = useState(false);
  const navigate = useNavigate();

  const handleResendEmail = async () => {
    setloading(true);
    try {
      const res = (await client.get(`/email/resend`)).data;
      if (res) {
        toast.success(
          "Email verification sent successfully please check your mail to verify your account"
        );
        setRequestSent(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log("error resending email", error.response);
    }
    setloading(false);
  };
  const mobile = useIsMobile();
  return (
    <MainLayouts>
    <div className="custom-course-background min-h-screen flex items-center justify-center">
      <div
        className="bg-white p-8 rounded-lg shadow-lg w-full sm:w-96"
        style={{
          width: mobile ? "100%" : "40%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <img
          src={mailicon}
          style={{
            height: "250px",
            objectFit: "contain",
          }}
        />
        <h1
          style={{
            fontSize: mobile ? "20px" : "30px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "10px",
            marginTop: "10px",
            color: "#000",
          }}
        >
          Check Email
        </h1>
        <p className="text-lg font-semibold mb-4 text-center"></p>
        <p className="text-gray-600 mb-4">
          Please check your email inbox and click on the provided link to verify
          your account and enjoy full access to our services. If you don't see
          the email, please check your spam or click on resend link
        </p>

        <button
          disabled={requestSent}
          className="bg-blue-500 text-white hover-bg-blue-600 py-2 px-4 rounded w-full"
          onClick={handleResendEmail}
        >
          {loading ? (
            <CircularProgress />
          ) : requestSent ? (
            "Verification Sent Already"
          ) : (
            "Resend Verification"
          )}
        </button>
      </div>
    </div>
    </MainLayouts>
  );
}

export default VerifyEmail;
