import React from "react";

export default function PasswordValidator({ password, passwordError }) {
  return (
    <>
      {password != "" && (
        <div style={{
          height:20,
          overflowY:"scroll"
        }}>
          {!passwordError.lengthError && (
            <p
              className="text-red-600"
              style={{
                fontSize: 14,
              }}
            >
              Password must be at least 8 characters long.
            </p>
          )}
          {!passwordError.uppercaseError && (
            <p
              className="text-red-600"
              style={{
                fontSize: 14,
              }}
            >
              Password must contain at least one uppercase letter.
            </p>
          )}
          {!passwordError.lowercaseError && (
            <p
              className="text-red-600"
              style={{
                fontSize: 14,
              }}
            >
              Password must contain at least one lowercase letter.
            </p>
          )}
          {!passwordError.specialCharacterError && (
            <p
              className="text-red-600"
              style={{
                fontSize: 14,
              }}
            >
              Password must contain at least one special character.
            </p>
          )}
          {!passwordError.numberRegex && (
            <p
              className="text-red-600"
              style={{
                fontSize: 14,
              }}
            >
              Password must contain at least one number.
            </p>
          )}
        </div>
      )}
    </>
  );
}
