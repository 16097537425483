import React from "react";

import Profile from "./Profile";

const Dashboard = () => {
  return (
    <div
      style={{
        minHeight: "70vh",
      }}
    >
      <Profile />
    </div>
  );
};

export default Dashboard;
