import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import { useIsMobile } from "../hooks/useIsMobile";

export function LinearProgressWithLabel(props) {
  // const mobile = useIsMobile()
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          sx={{
            height: 10,
            bgcolor: "#333",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#d15142",
            },
          }}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box>
        <Typography ml={2} variant="body2" color="#d15142">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
