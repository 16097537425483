import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import client from "../../../api/client";
import { GlobalContext } from "../../../context";
import {
  UpdateUserInfo,
  UpdateUserPassword,
  getCurrentUser,
  logoutHandler,
} from "../../../context/actions/auth";
import ProfilePictureUploader from "../../Dashboard/ProfileUploader";
import CircularProgress from "../../Generic/CircularProgress";
import { useIsMobile } from "../../hooks/useIsMobile";
import { uploadHelmper } from "../../../uploadCDN";
export default function VonSettings() {
  const {
    authState: { data },
    authDispatch,
  } = useContext(GlobalContext);
  const user = data?.user || {};

  const [retypePassword, setRetypePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    telephone: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState({
    new_password: "",
    current_password: "",
  });
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  const [editProfile, setEditProfile] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    setFormData({
      firstname: user.firstname,
      lastname: user?.lastname,
      telephone: user?.telephone,
    });
  }, []);
  // Update Profile
  const handleUpdateUser = async (e) => {
    e.preventDefault();
    setProfileLoading(true);
    const res = await UpdateUserInfo(formData, authDispatch);
    if (res) {
      toast.success("Profile updated successfully");
    }
    setProfileLoading(false);
  };
  const handelPasswordChange = async (e) => {
    e.preventDefault();
    if (password?.new_password === confirmPassword) {
      const res = await UpdateUserPassword(password, authDispatch);
      if (res) {
        toast.success("Password updated successfully");
      }
    } else if (password?.new_password !== confirmPassword) {
      toast.error("Passwords do not match");
    }
  };

  // Logic to logout
  const navigate = useNavigate();

  return (
    <section
      style={{
        minHeight: "100vh",
      }}
    >
      <div className="bg-[#eef1f7] p-[5%]">
        <ProfilePictureUploader />
        <div
          style={{
            width: "100%",
            marginTop: 40,
            display: "flex",
            // alignItems: "center",
            flexDirection: mobile ? "column" : "row",
            justifyContent: "space-between",
            gap: mobile ? 20 : 0,
          }}
        >
          <div
            style={{
              width: mobile ? "100%" : "48%",
            }}
          >
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <p className="font-bold text-sm mb-1">First Name</p>

              <input
                // type={showPassword ? "text" : "password"}
                name="First Name"
                placeholder="First Name"
                value={formData?.firstname}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    firstname: e.target.value,
                  });
                }}
                style={{
                  height: 60,
                }}
                className={`w-full mt-[2%]  inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm"
                      }`}
              />
            </div>
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <p className="font-bold text-sm mb-1">Last Name</p>

              <input
                // type={showPassword ? "text" : "password"}
                name="Last Name"
                placeholder="Last Name"
                value={formData?.lastname}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    lastname: e.target.value,
                  });
                }}
                style={{
                  height: 60,
                }}
                className={`w-full mt-[2%]  inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm"
                      }`}
              />
            </div>

            <div
              style={{
                marginBottom: 20,
              }}
            >
              <p className="font-bold text-sm mb-1">Phone Number</p>

              <input
                // type={showPassword ? "text" : "password"}
                name="Phone Number"
                placeholder="Phone Number"
                value={formData?.telephone}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    telephone: e.target.value,
                  });
                }}
                style={{
                  height: 60,
                }}
                className={`w-full mt-[2%]  inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm"
                      }`}
              />
            </div>
            <button
              onClick={handleUpdateUser}
              style={{
                marginBottom: "50px",
                marginTop: "20px",
                width: "100%",
              }}
              className="
    bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#d15242_50%] to-[#2c75e4_50%]
    text-white font-bold transition-all delay-0 duration-300 ease-out
    py-4 sm:py-4 lg:py-4 px-4 sm:px-6 lg:px-8 rounded tracking-widest
    text-xs sm:text-sm lg:text-base
  "
            >
              {profileLoading ? <CircularProgress /> : "UPDATE PROFILE"}
            </button>
          </div>
          <div
            style={{
              width: mobile ? "100%" : "48%",
            }}
          >
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <p className="font-bold text-sm mb-1">Current Password</p>

              <input
                // type={showPassword ? "text" : "password"}
                name="Current Password"
                placeholder="Current Password"
                type="password"
                value={password.current_password}
                onChange={(e) => {
                  setPassword({
                    ...password,
                    current_password: e.target.value,
                  });
                }}
                style={{
                  height: 60,
                }}
                className={`w-full mt-[2%]  inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm"
                      }`}
              />
            </div>
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <p className="font-bold text-sm mb-1">New Password</p>

              <input
                // type={showPassword ? "text" : "password"}
                name="New Password"
                placeholder="New Password"
                type="password"
                value={password.new_password}
                onChange={(e) => {
                  setPassword({
                    ...password,
                    new_password: e.target.value,
                  });
                }}
                style={{
                  height: 60,
                }}
                className={`w-full mt-[2%]  inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm"
                      }`}
              />
            </div>
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <p className="font-bold text-sm mb-1">Confirm Password</p>

              <input
                type="password"
                // type={showPassword ? "text" : "password"}
                name="Confirm Password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                style={{
                  height: 60,
                }}
                className={`w-full mt-[2%]  inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm"
                      }`}
              />
            </div>
            <button
              onClick={handelPasswordChange}
              style={{
                marginBottom: "50px",
                marginTop: "20px",
                width: "100%",
              }}
              className="
    bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#d15242_50%] to-[#2c75e4_50%]
    text-white font-bold transition-all delay-0 duration-300 ease-out
    py-4 sm:py-4 lg:py-4 px-4 sm:px-6 lg:px-8 rounded tracking-widest
    text-xs sm:text-sm lg:text-base
  "
            >
              {passwordLoading ? <CircularProgress /> : "Change Password"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
