import React from "react";
import MainLayouts from "../layout/MainLayouts";

const ErrorPage = ({ error }) => {
  const status = error?.status || "500";
  const message =
    error?.message || "Something went wrong. Please try again later.";

  return (
    <MainLayouts>
    <div className="flex flex-col items-center justify-center h-screen ">
      <h1 className="text-4xl font-bold text-red-500">Error {status}</h1>
      <p className="text-xl text-gray-600">{message}</p>
    </div>
    </MainLayouts>
  );
};

export default ErrorPage;
