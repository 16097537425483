import React, { useContext, useEffect } from "react";
import VonSettings from "../AllVon/VonSettings/VonSettings";
import { GlobalContext } from "../../context";
import { logoutHandler } from "../../context/actions/auth";
import { useNavigate } from "react-router-dom";
import { PiPowerBold } from "react-icons/pi";
import MainLayouts from "../../layout/MainLayouts";

export default function Settings() {
  const {
    authDispatch,
    authState: { loggedIn },
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleLogout = async () => {
    await logoutHandler(authDispatch);
    navigate("/");
  };
  useEffect(() => {
    if (loggedIn === false) {
      navigate("/sign-in");
    }
  }, [loggedIn]);
  return (
    <MainLayouts>
      <div className="mt-[30%] lg:mt-[15%] mb-[5%] px-[3.2%]  md:px-[7%]">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1
            style={{
              fontSize: 30,
              marginBottom: 20,
              marginTop: 20,
              fontWeight: 500,
              color: "#000",
            }}
          >
            My Profile
          </h1>
          <button
            style={{
              background: "red",
              color: "#fff",
            }}
            className="
    bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#d15242_50%] to-[#eef1f7_50%]
    text-black hover:text-white transition-all delay-0 duration-300 ease-out
    py-4 sm:py-4 lg:py-4 px-4 sm:px-6 lg:px-8 rounded tracking-widest
    flex items-center gap-2 text-xs sm:text-sm lg:text-base
  "
            onClick={handleLogout}
          >
            <span>
              <PiPowerBold />
            </span>
            Logout
          </button>
        </div>

        <div className="relative w-full flex items-center before:absolute before:w-[4.5%] before:left-0 before:h-[3px] before:bg-[#d15242] after:absolute after:bg-[#dcdcdc] after:h-[1px] after:w-[94%] after:right-0 mb-[2%]"></div>
        <VonSettings />
      </div>
    </MainLayouts>
  );
}
