import Image from "../../assets/images/testimg.jpg";
function ProjectItem({
  description,
  file_link,
  title,
  image,
  user: { firstname, lastname },
}) {
  return (
    <div className="flex items-center gap-2 mb-3">
      <img
        src={image}
        style={{
          height: 100,
          width: 150,
          objectFit: "contain",
        }}
      />
      <div>
        <h1
          style={{
            fontWeight: 500,
            marginBottom: 3,
          }}
        >
          <a href={file_link}>
            <span
              style={{
                textDecoration: "underline",
                marginRight: 7,
              }}
            >
              {title}
            </span>
          </a>
          by {firstname + " " + lastname}
        </h1>
        <p
          style={{
            fontSize: 14,
            color: "#aaa",
          }}
        >
          {description}
        </p>
      </div>
    </div>
  );
}
export default ProjectItem;
