import React from "react";
import MainLayouts from "../layout/MainLayouts";

export default function PrivacyPolicy() {
  return (
    <MainLayouts>
    <div
      className="mx-[7%] mt-[30%] md:mt-[15%]"
      style={{
        marginBottom: 100,
      }}
    >
      <h1
        style={{
          fontWeight: 600,
          textAlign: "center",
          fontSize: 35,
          marginBottom: 30,
        }}
      >
        Privacy Policy
      </h1>
      <p>
        E1x Academy operates the E1xAcademy.net website, referred to from here
        on as the service.
      </p>
      <p>
        This page serves to inform website visitors about our policies regarding
        the collection, use, and disclosure of personal information while using
        the E1x Academy website.
      </p>
      <p>
        By opting to use our service, you consent to the collection and
        utilisation of information in accordance with this policy. The personal
        information gathered is utilised for the provision and enhancement of
        the service. We commit not to utilise or disclose your information to
        any party except as outlined in this privacy policy.
      </p>
      <h2
        style={{
          marginTop: 30,
          fontWeight: 600,
          marginBottom: 30,
          fontSize: 20,
        }}
      >
        Information Collection and Use
      </h2>
      <p>
        To enhance your experience while utilizing our service, we may request
        certain personally identifiable information, including but not limited
        to your name, phone number, and postal address. The information
        collected will be used for communication purposes or to identify you.
      </p>
      <h2
        style={{
          marginTop: 30,
          fontWeight: 600,
          marginBottom: 30,
          fontSize: 20,
        }}
      >
        Log Data
      </h2>
      <p>
        Please be informed that each time you visit our service, we gather
        information known as log data, which your browser transmits to us. This
        log data may comprise details such as your device's Internet Protocol
        ("IP") address, browser version, pages visited within our service, date
        and time of visit, duration spent on those pages, and other statistics.
      </p>
      <h2
        style={{
          marginTop: 20,
          fontWeight: 600,
          marginBottom: 20,
          fontSize: 20,
        }}
      >
        Cookies
      </h2>
      <p>
        Cookies consist of small data files commonly utilized as anonymous,
        unique identifiers. These are transmitted to your browser from the
        visited website and stored on your device.
      </p>
      <p>
        Our website employs these "cookies" to collect information and enhance
        our service. You have the option to accept or decline these cookies and
        receive notification when a cookie is sent to your computer. However,
        opting to decline our cookies may affect the quality of your usage.
      </p>
      <h2>Third-party service Providers</h2>
      <p>
        We may engage third-party entities and individuals for the following
        purposes:
      </p>
      <ul>
        <li>To facilitate our service,</li>
        <li>To deliver the service on our behalf,</li>
        <li>To provide services related to the service or</li>
        <li>To aid in analysing how our service is utilised.</li>
      </ul>
      <p>
        We wish to notify our service users that these third parties may access
        your personal information to perform the tasks delegated to them on our
        behalf. Nonetheless, they are bound not to disclose or utilise the
        information for any other purpose.
      </p>
      <h2
        style={{
          marginTop: 20,
          fontWeight: 600,
          marginBottom: 20,
          fontSize: 20,
        }}
      >
        Security
      </h2>
      <p>
        We appreciate the trust you place in us by providing your personal
        information. Consequently, we endeavour to employ commercially
        acceptable means to safeguard it. However, it is important to note that
        no transmission method over the internet or electronic storage method is
        entirely secure and reliable, and we cannot guarantee absolute security.
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        Our privacy policy may undergo periodic updates. Hence, we recommend
        reviewing this page regularly for any revisions. We will notify you of
        any changes by posting the updated privacy policy on this page. These
        modifications take effect immediately upon posting.
      </p>
      <h2
        style={{
          marginTop: 20,
          fontWeight: 700,
          marginBottom: 20,
          fontSize: 20,
        }}
      >
        Contact Us
      </h2>
      <p>
        Should you have any questions or suggestions regarding our privacy
        policy, feel free to <a href="#">contact us</a>.
      </p>
    </div>
    </MainLayouts>
  );
}
