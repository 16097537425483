import { ArrowBack, Close } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DocumentIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.jpeg";
import { GlobalContext } from "../../context";
import { uploadHelmper2 } from "../../uploadCDN";
import { sendMessage } from "../../utility";
import ChatComponent from "./ChatComponent";
import ReplyComponent from "./ReplyComponent";
import { LinearProgressWithLabel } from "./UploadProgress";

function Chat({
  chat = [],
  chatId,
  chatName,
  mobile,
  onClickPlusButton = () => {},
}) {
  const [open1, setOpen1] = React.useState(false);

  const handleProgressOpen = () => {
    setOpen1(true);
  };

  const handleProgressClose = () => {
    setOpen1(false);
  };
  const [replyData, setReplyData] = useState({});
  const messagesEndRef = useRef(null);
  const {
    authState: { data },
  } = useContext(GlobalContext);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const handleClick1 = () => {
    document.getElementById("fileInput").click();
  };

  const defaultData = () => {
    setFile(null);
    setFileType("");
    setSelectedImage("");
    setFormData({
      ...formData,
      type: "",
    });
  };

  const { authState } = useContext(GlobalContext);
  const id = authState?.data?.user?.id?.toString();
  const [formData, setFormData] = useState({
    id: data?.user?.id?.toString(),
    type: "",
    image: "",
    message: "",
    // time: "",
    docUrl: "",
    videoUrl: "",
    name: data?.user?.firstName + " " + data?.user?.lastName,
    replyId: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (selectedImage) {
      defaultData();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (data) => {
    if (data) {
    } else {
      setAnchorEl(null);
    }
  };
  const navigate = useNavigate();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedImage, setSelectedImage] = useState("");
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFile(file);
    }
  };

  useEffect(() => {
    if (fileType) {
      handleClick1();
    }
  }, [fileType]);

  const clear = () => {
    setFile(null);
    setSelectedImage("");
    setFormData({
      id: data?.user?.id?.toString(),
      type: "",
      image: "",
      message: "",
      // time: "",
      docUrl: "",
      videoUrl: "",
      replyId: "",
      name: data?.user?.firstname + " " + data?.user?.lastname,
    });
  };
  useEffect(() => {
    setFormData({
      id: data?.user?.id?.toString(),
      type: "",
      image: "",
      message: "",
      // time: "",
      docUrl: "",
      videoUrl: "",
      replyId: "",
      name: data?.user?.firstname + " " + data?.user?.lastname,
    });
  }, [data]);

  const [loading, setLoading] = useState(false);
  const HandleSendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (file) {
      setOpen1(true);
      try {
        const res = await uploadHelmper2(file, setUploadProgress);
        const res1 = await sendMessage(chatId, {
          ...formData,
          docUrl: formData.type === "doc" ? res?.url : "",
          videoUrl: formData.type === "video" ? res?.url : "",
          image: formData?.type === "image" ? res?.url : "",
        });
        if (res1) {
          clear();
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      const res = await sendMessage(chatId, formData);
      if (res) {
        clear();
      }
    }
    setLoading(false);
    handleProgressClose();
  };
  useEffect(() => {
    if (replyData != "" && chat?.length > 0) {
      const res = chat?.find((item) => item?.chatId === formData?.replyId);
      setReplyData(res);
    }
  }, [formData.replyId]);
  useEffect(() => {
    if (formData.type === "doc") {
      setFileType(
        "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
    } else if (formData.type === "image") {
      setFileType("image/*");
    }
  }, [formData.type]);
  return (
    <div
      style={{ minHeight: mobile ? "100vh" : "100vh", position: "relative" }}
    >
      <Dialog
        // fullWidth
        open={open1}
        PaperProps={{
          style: {
            minWidth: mobile ? 200 : 500,
          },
        }}
        onClose={handleProgressClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Uploading File Please Wait..</DialogTitle>
        <div
          style={{
            padding: "20px 20px 40px 20px",
          }}
        >
          <LinearProgressWithLabel value={uploadProgress} />
        </div>
      </Dialog>
      {chatName && (
        <header
          style={{
            padding: mobile ? 15 : "15px 25px",
            background: "#fff",
            width: "100%",
            borderTopLeftRadius: mobile ? 0 : 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: !mobile ? "initial" : "fixed",
            top: 0,
            zIndex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: mobile ? 15 : 20,
            }}
          >
            {mobile && (
              <ButtonBase
                onClick={() => {
                  onClickPlusButton();
                }}

                // style={{
                //   background: "#d15242",
                //   height: 70,
                //   width: 70,
                //   borderRadius: 50,
                // }}
              >
                <ArrowBack
                  style={{
                    fontSize: 30,
                    color: "#d15242",
                  }}
                />
              </ButtonBase>
            )}
            <Avatar
              style={{ height: mobile ? 45 : 55, width: mobile ? 45 : 55 }}
            />
            <div>
              <h1
                style={{
                  fontSize: mobile ? 15 : 18,
                  fontWeight: 600,
                  lineHeight: 1.2,
                }}
              >
                {chatName}
              </h1>
              <p style={{ color: "#d15242", fontSize: mobile ? 12 : 14 }}>
                30 active users
              </p>
            </div>
          </div>
        </header>
      )}

      <div
        style={{
          padding: mobile ? "95px 15px" : "0px 25px",
          overflowY: "scroll",
          height: mobile ? "100%" : "82vh",
        }}
      >
        {chat?.length > 0 ? (
          chat?.map((cur, i) => (
            <ChatComponent
              mobile={mobile}
              data={chat}
              onReplyClick={(e) => {
                setFormData({
                  ...formData,
                  replyId: e,
                });
              }}
              cur={cur}
              key={i}
              id={id}
              cohortId={chatId}
            />
          ))
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                maxWidth: mobile ? "90vw" : "30vw",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={logo}
                style={{
                  height: 200,
                  width: 200,
                  objectFit: "contain",
                }}
              />
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: 600,
                  marginTop: "-5%",
                }}
              >
                No message yet ??
              </h1>
              <p>click on a cohort to start or join conversion now</p>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      {chatName === "" && mobile && (
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 20,
          }}
        >
          <ButtonBase
            onClick={() => {
              navigate(-1);
            }}
            style={{
              marginTop: 20,
            }}
            // style={{
            //   background: "#d15242",
            //   height: 70,
            //   width: 70,
            //   borderRadius: 50,
            // }}
          >
            <ArrowBack
              style={{
                fontSize: 30,
                color: "#d15242",
              }}
            />
          </ButtonBase>
        </div>
      )}
      {chatName === "" && mobile && (
        <div
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
          }}
        >
          <ButtonBase
            onClick={() => {
              onClickPlusButton();
            }}
            style={{
              background: "#d15242",
              height: 70,
              width: 70,
              borderRadius: 50,
            }}
          >
            <FaPlus
              style={{
                color: "#fff",
              }}
            />
          </ButtonBase>
        </div>
      )}
      {chatName && (
        <footer
          style={{
            padding: mobile ? 15 : "19px 20px",
            background: "#fff",
            width: "100%",
            position: mobile ? "fixed" : "absolute",
            bottom: mobile ? 0 : 2,
            left: 0,
            right: 0,
            overflow: "hidden",
            borderBottomLeftRadius: mobile ? 0 : 40,
          }}
        >
          {formData?.replyId != "" && <ReplyComponent data={replyData} />}
          {fileType ===
            "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            selectedImage && (
              <div
                style={{
                  minHeight: 80,
                  background: "#f7f7f7",
                  borderRadius: 10,
                  width: 300,
                  marginBottom: 20,
                  padding: "16px 20px",
                }}
              >
                <h1>
                  <span
                    style={{
                      color: "#d15242",
                    }}
                  >
                    Name:
                  </span>
                  {file?.name}
                </h1>
                <p>
                  {" "}
                  <span
                    style={{
                      color: "#d15242",
                    }}
                  >
                    Size:
                  </span>
                  {(file?.size / 1048576).toFixed(2)} MB
                </p>
              </div>
            )}
          {selectedImage &&
            fileType !=
              "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
              <div>
                <img
                  src={selectedImage}
                  // title="selected"
                  style={{
                    height: 200,
                    width: 300,
                    marginBottom: 10,
                    borderRadius: 15,
                  }}
                />
              </div>
            )}

          <form
            onSubmit={HandleSendMessage}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
                style: { borderRadius: 10 },
              }}
            >
              <MenuItem
                onClick={() => {
                  setFormData({
                    ...formData,
                    type: "image",
                  });

                  handleClose();
                }}
                style={{ marginBottom: 5 }}
              >
                <ListItemIcon>
                  <ImageIcon style={{ color: "#d15242" }} />
                </ListItemIcon>
                Image
              </MenuItem>
              <MenuItem
                style={{ marginBottom: 5 }}
                onClick={() => {
                  setFormData({
                    ...formData,
                    type: "doc",
                  });

                  handleClose();
                }}
              >
                <ListItemIcon>
                  <DocumentIcon style={{ color: "#d15242" }} />
                </ListItemIcon>
                Documents
              </MenuItem>
            </Menu>
            <ButtonBase
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ marginRight: 20 }}
            >
              {selectedImage ? (
                <Close style={{ color: "red" }} />
              ) : (
                <AttachFileIcon style={{ fontSize: mobile ? 25 : 27 }} />
              )}
            </ButtonBase>
            <input
              id="fileInput"
              type="file"
              accept={fileType || "image/*"}
              onChange={handleFileChange}
              className="hidden"
              style={{ display: "none" }}
            />
            <TextField
              value={formData.message}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  message: e.target.value,
                });
              }}
              size="small"
              fullWidth
              placeholder="Send message..."
              multiline
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 20,
                  "& fieldset": {
                    borderColor: "#aaa",
                    borderWidth: 1,
                  },
                  "&:hover fieldset": {
                    borderColor: "#aaa",
                    borderWidth: 1,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#aaa",
                    borderWidth: 1,
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#000",
                },
              }}
            />
            <ButtonBase
              disabled={
                loading
                // loading ||
                // (formData.message === "" &&
                //   formData.message === "" &&
                //   formData?.docUrl === "" &&
                //   formData?.image === "")
              }
              type="submit"
              style={{ marginLeft: 20 }}
            >
              {loading ? (
                <CircularProgress
                  size={20}
                  style={{ color: "#d15242", fontSize: 20 }}
                />
              ) : (
                <SendIcon
                  style={{ color: "#d15242", fontSize: mobile ? 25 : 27 }}
                />
              )}
            </ButtonBase>
          </form>
        </footer>
      )}
    </div>
  );
}

export default Chat;
