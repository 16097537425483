import AuthLayout from "../../layout/AuthLayouts";

import { ButtonBase, MenuItem, TextField } from "@mui/material";
import CircularProgress from "../../components/Generic/CircularProgress";
import Logo from "../../assets/images/logo.jpeg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useIsMobile } from "../../components/hooks/useIsMobile";
import { useContext, useEffect, useState } from "react";
import Animator from "../../components/Aminator/Animator";
import { BiArrowBack } from "react-icons/bi";
import { GlobalContext } from "../../context";
import {
  Referral,
  fetchSignUpLocation,
  isPasswordValid,
  validatePassword,
} from "../../utility";
import { signupHandler, googleHandler } from "../../context/actions/auth";
import { toast } from "react-toastify";
import GoogleButton from "react-google-button";
import PasswordValidator from "../../components/Generic/PasswordValidator";
export default function SignUpPage() {
  const mobile = useIsMobile();
  const [show, setShow] = useState(false);
  const {
    countryCodeState: { data },
    authDispatch,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [referral, setReferral] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [other, setOther] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const [googleLoading, setGoogleLoading] = useState(false);
  const login = useGoogleLogin({
    onSuccess: (res) => {
      handleGoogleSubmit(res);
    },
  });
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = (e) => {
    e.stopPropagation();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [passwordError, setPasswordError] = useState({});
  const handleGoogleSubmit = async (data) => {
    setGoogleLoading(true);

    const res = await googleHandler(
      {
        access_token: data?.access_token,

        location: { region: region, country: country, city: city },
      },
      authDispatch
    );

    if (res) {
      navigate("/");
    }
    setGoogleLoading(false);
  };
  useEffect(() => {
    const error = validatePassword(password);

    setPasswordError(error);
  }, [password]);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    if (isPasswordValid(passwordError)) {
      const res = await signupHandler(
        {
          email: email,
          password: password,

          referral: other === "" ? referral : other,
          firstname: firstName,
          lastname: lastName,
          telephone: telephone,
          location: {
            country: country,
            region: region,
            city: city,
          },
          country_code: selectedCountryCode,
        },
        authDispatch
      );
      if (res) {
        navigate("/vonverify");
      }
    } else {
      toast.error("Passwords does not match our requirements");
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSignUpLocation().then((res) => {
      setCountry(res?.country);
      setRegion(res?.region);
      setCity(res?.city);
    });
  }, []);

  return (
    <AuthLayout>
      {show ? (
        <div
          style={{
            textAlign: mobile ? "center" : "left",
            display: "flex",
            alignItems: mobile ? "center" : "flex-start",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            animation: show ? "zoomIn .5s forwards" : "zoomOut .5s forwards",
          }}
        >
          <ButtonBase
            onClick={() => {
              setShow(false);
            }}
            style={{
              background: "#f7f7f7",
              padding: 15,
              borderRadius: 30,
              marginBottom: 30,
            }}
          >
            <BiArrowBack
              style={{
                color: "#000",
              }}
            />
          </ButtonBase>
          <div
            style={{
              width: "100%",
            }}
          >
            <form onSubmit={handleSubmit}>
              <h2 className="text-2xl font-bold text-center mb-[5%]">
                Register a new account
              </h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  label="First Name"
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  InputProps={{
                    style: {
                      height: 50,
                      borderRadius: 5,
                      background: "#fff",
                      color: "#000",
                      fontWeight: 300,
                    },
                  }}
                  fullWidth
                  style={{
                    marginBottom: 20,
                    width: "49%",
                  }}
                />
                <TextField
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  label="Last Name"
                  InputProps={{
                    style: {
                      height: 50,
                      borderRadius: 5,
                      background: "#fff",
                      color: "#000",
                      fontWeight: 300,
                    },
                  }}
                  fullWidth
                  style={{
                    marginBottom: 20,
                    width: "49%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  required
                  value={selectedCountryCode}
                  onChange={(e) => {
                    setSelectedCountryCode(e.target.value);
                  }}
                  label="Country Code"
                  InputProps={{
                    style: {
                      height: 50,
                      borderRadius: 5,
                      background: "#fff",
                      color: "#000",
                      fontWeight: 300,
                    },
                  }}
                  select
                  fullWidth
                  style={{
                    marginBottom: 20,
                    width: "40%",
                  }}
                >
                  {data?.length > 0 &&
                    data?.map((cur, i) => (
                      <MenuItem key={i} value={cur.code}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={cur.flag}
                            style={{
                              height: 30,
                              width: 30,
                              objectFit: "contain",
                              marginRight: 10,
                            }}
                          />{" "}
                          {cur?.code + " "}
                          {cur?.name?.slice(0, 30)}
                        </div>
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  required
                  value={telephone}
                  onChange={(e) => {
                    setTelephone(e.target.value);
                  }}
                  label="Phone number"
                  InputProps={{
                    style: {
                      height: 50,
                      borderRadius: 5,
                      background: "#fff",
                      color: "#000",
                      fontWeight: 300,
                    },
                  }}
                  fullWidth
                  style={{
                    marginBottom: 20,
                    width: "58%",
                  }}
                />
              </div>
              <TextField
                required
                value={referral}
                // value={selectedCountryCode}
                onChange={(e) => {
                  setReferral(e.target.value);
                }}
                label="Referral"
                InputProps={{
                  style: {
                    height: 50,
                    borderRadius: 5,
                    background: "#fff",
                    color: "#000",
                    fontWeight: 300,
                  },
                }}
                fullWidth
                style={{
                  marginBottom: 20,
                }}
                select
              >
                {Referral?.map((cur, i) => (
                  <MenuItem value={cur} key={i}>
                    {cur}
                  </MenuItem>
                ))}
              </TextField>
              <div>
                {referral === "Other" && (
                  <input
                    type="text"
                    name="How did you hear about us"
                    value={other}
                    placeholder="How did you hear about us"
                    onChange={(e) => setOther(e.target.value)}
                    required
                    className="w-full inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm mb-[5%]"
                  />
                )}
              </div>
              <TextField
                required
                label="Email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  style: {
                    height: 50,
                    borderRadius: 5,
                    background: "#fff",
                    color: "#000",
                    fontWeight: 300,
                  },
                }}
                fullWidth
                style={{
                  marginBottom: 20,
                }}
              />
              <TextField
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                label="Password"
                InputProps={{
                  style: {
                    height: 50,
                    borderRadius: 5,
                    background: "#fff",
                    color: "#000",
                    fontWeight: 300,
                  },
                }}
                fullWidth
                style={{
                  marginBottom: 10,
                }}
              />{" "}
              {password != "" && (
                <PasswordValidator
                  password={password}
                  passwordError={passwordError}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              ></div>
              <button
                type="submit"
                style={{
                  marginBottom: 15,
                }}
                className="w-full py-3 px-5 rounded text-sm bg-primary-color hover:bg-[#884c34] delay-0 duration-150 transition-colors ease-in text-white font-medium mt-[5%]"
              >
                {loading ? <CircularProgress /> : "Sign Up"}
              </button>{" "}
            </form>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
            </div>
            <p className="mt-[3%] text-sm text-center font-medium">
              Already have an account?{" "}
              <Link to="/sign-up" className="text-primary-color">
                Login Now
              </Link>
              .
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: mobile ? "center" : "left",
            display: "flex",
            alignItems: mobile ? "center" : "flex-start",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={Logo}
            style={{
              width: 120,
              height: 40,
              objectFit: "contain",
              marginBottom: 10,
            }}
            alt="Logo"
          />
          <h1
            style={{
              fontSize: mobile ? 18 : 27,
              marginBottom: mobile ? 10 : 30,
            }}
          >
            Sign Up to Unlock Entrepreneurial Excellence.
          </h1>

          <div
            style={{
              width: "100%",
            }}
          >
            <button
              onClick={() => {
                setShow(true);
              }}
              style={{
                marginBottom: 15,
                width: "100%",
              }}
              className="w-full py-4 px-5 rounded text-sm bg-primary-color hover:bg-[#884c34] delay-0 duration-150 transition-colors ease-in text-white font-medium mt-[5%]"
            >
              Sign up with Email
            </button>

            <div style={{ width: "100%", marginTop: 5 }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GoogleButton
                  label={
                    googleLoading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      "Sign up with Google"
                    )
                  }
                  onClick={() => {
                    login();
                  }}
                  style={{
                    width: "100%",
                  }}
                ></GoogleButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </AuthLayout>
  );
}
