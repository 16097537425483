import React, { useState } from "react";
import StarIcon from "./StarIcon";
import { compareAsc, format } from "date-fns";
export default function RatingContent({ comment, user, stars, created_at }) {
  const [readMore, setReadMore] = useState(false);
  const text = comment || "";

  return (
    <div
      style={{
        minHeight: 200,
        background: "#fff",
        width: "100%",
        marginBottom: 20,
        borderRadius: 10,
        padding: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            marginBottom: 8,
          }}
        >
          <h1
            style={{
              fontWeight: 500,
            }}
          >
            {user?.firstname + " " + user?.lastname}
          </h1>
          <div className="flex">
            {[...Array(+stars || 0)].map((_, index) => (
              <StarIcon key={index} filled={index < 5} />
            ))}
          </div>
        </div>
        <p
          style={{
            color: "#aaa",
          }}
        >
          {format(new Date(created_at || ""), "yyyy-MM-dd")}
        </p>
      </div>
      <div>
        {text?.slice(0, readMore ? text?.length : 200)}
        {text?.length > 200 && (
          <button
            onClick={() => {
              setReadMore(!readMore);
            }}
            style={{
              color: "blue",
              fontSize: 12,
              marginLeft: 5,
            }}
          >
            {readMore ? "Read less" : "Read more"}
          </button>
        )}
      </div>
    </div>
  );
}
