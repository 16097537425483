import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import client from "../../api/client";
import CircularProgress from "../../components/Generic/CircularProgress";
import AuthLayout from "../../layout/AuthLayouts";

import { TextField } from "@mui/material";
import Logo from "../../assets/images/logo.jpeg";
import { useIsMobile } from "../../components/hooks/useIsMobile";
export default function ResetPasswordPage() {
  const mobile = useIsMobile();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
    token: token,
    email: email,
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password === formData.password_confirmation) {
      setLoading(true);
      try {
        const res = await client.post(
          "forgot-password/password/update",
          formData
        );
        if (res) toast.success("Password updated successfully");
        navigate("/sign-in");
      } catch (error) {
        console.log("error reseting password", error.response);
        toast.error(error?.response?.data?.msg);
      }
      setLoading(false);
    } else {
      setErrors({
        password_confirmation: "Passwords do not match",
      });
    }
  };
  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Extract the token and email using regular expressions
    const tokenMatch = currentUrl.match(/token=([^&]*)/);
    const emailMatch = currentUrl.match(/email=([^&]*)/);

    // Check if both token and email were found
    if (tokenMatch && emailMatch) {
      // Decode the token and email (if they're URL encoded)
      const token = decodeURIComponent(tokenMatch[1]);
      const email = decodeURIComponent(emailMatch[1]);
      setToken(token);
      setEmail(email);
      setFormData({
        ...formData,
        token: token,
        email: email,
      });
      // Now you can use the token and email as needed
    } else {
      toast.error("Invalid token or email provided");
    }
  }, []);
  return (
    <AuthLayout>
      <div
        style={{
          textAlign: mobile ? "center" : "left",
          display: "flex",
          alignItems: mobile ? "center" : "flex-start",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <img
          src={Logo}
          style={{
            width: 120,
            height: 60,
            objectFit: "contain",
            marginBottom: 10,
          }}
          alt="Logo"
        />
        <h1
          className="font-bold text-center mb-[5%]"
          style={{
            fontSize: mobile ? 18 : 27,
          }}
        >
          Reset Password
        </h1>

        <div
          style={{
            width: "100%",
          }}
        >
          <form
            style={{
              width: "100%",
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              required
              label="New password"
              type="password"
              placeholder="New password"
              value={formData.password}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  password: e.target.value,
                });
                setErrors({});
              }}
              InputProps={{
                style: {
                  height: 55,
                  borderRadius: 5,
                  background: "#fff",
                  color: "#000",
                  fontWeight: 300,
                },
              }}
              fullWidth
              style={{
                marginBottom: 20,
              }}
            />
            <TextField
              required
              type="password"
              label="Confirm password"
              value={formData.password_confirmation}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  password_confirmation: e.target.value,
                });
                setErrors({});
              }}
              InputProps={{
                style: {
                  height: 55,
                  borderRadius: 5,
                  background: "#fff",
                  color: "#000",
                  fontWeight: 300,
                },
              }}
              fullWidth
              style={{
                marginBottom: 5,
              }}
            />
            {errors.password_confirmation && (
              <p
                className="error"
                style={{
                  color: "red",
                  fontSize: 10,
                  marginTop: 10,
                }}
              >
                {errors.password_confirmation}
              </p>
            )}
            <NavLink
              to="/sign-in"
              className="text-sm mt-[1%] text-primary-color text-right block cursor-pointer"
            >
              Login instead
            </NavLink>

            {/* <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}>
                    <NavLink
                      style={{
                        width: mobile ? "60%" : "30%"
                      }}
                      to="/forgot-password"
                      className="text-sm mt-[2%] text-primary-color text-right block cursor-pointer"
                    >
                      Forgot password
                    </NavLink>
                  </div> */}

            <button
              type="submit"
              className="w-full py-3 px-5 rounded text-sm bg-primary-color hover:bg-[#884c34] delay-0 duration-150 transition-colors ease-in text-white font-medium mt-[5%]"
            >
              {loading ? <CircularProgress /> : "Reset Password"}
            </button>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
}
