import React from "react";
import Successful from "../components/Sucessful/Successful";
import MainLayouts from "../layout/MainLayouts";

const SuccessfulPage = () => {
  return (
    <MainLayouts>
      <Successful />
    </MainLayouts>
  );
};

export default SuccessfulPage;
