import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { CourseDetailContext } from "../../services/CourseDetails";
import VonDisplayText from "../AllVon/VonDisplayText/VonDisplayText";

export default function VonOnlineDescription({ courses }) {
  return (
    <div className="text-[#555555] p-7 sm:p-10">
      <div className="mb-[5%]">
        <h2 className="text-2xl font-semibold mb-[3%]">Program Features</h2>
        <VonDisplayText htmlContent={courses?.program_features} />
      </div>
      <div className="mb-[5%]">
        <VonDisplayText htmlContent={courses?.what_you_will_learn} />
      </div>
      <div className="mb-[5%]">
        <h2 className="font-semibold mb-[1.5%]">Skills</h2>
        <ul>
          {courses?.skill?.map((skill, index) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
      </div>
      <div className="mb-[5%]">
        <h2 className="font-semibold mb-[3%]">Program Prerequisite</h2>
        <VonDisplayText htmlContent={courses?.program_prerequisite} />
      </div>
    </div>
  );
}
