import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import client from "../../api/client";
import { storage } from "../../firebase";
import { errorMessage } from "../../utility";

const UploadAnswers = ({ id, onClose }) => {
  const [formData, setFormData] = useState({
    exercise_id: id,
    file_link: "",
  });
  const [loading, setLoading] = useState(false);

  const handlesubmit = async () => {
    setLoading(true);
    try {
      const res = await client.post(
        `courses/live/live-courses/assignments`,
        formData
      );
      onClose();
      toast.success("Assignment Uploaded Sucessfully");
      // onClick();

      setDownloadURL1(null);
    } catch (error) {
      onClose();
      toast.error(errorMessage(error));
    }
    setLoading(false);
  };
  useEffect(() => {
    setFormData({
      ...formData,
      exercise_id: id,
    });
  }, [id]);
  const [file, setFile] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [downloadURL, setDownloadURL] = useState(null);
  const [downloadURL1, setDownloadURL1] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    if (formData.problem_file != "") {
      e.preventDefault();
      setIsDragging(false);
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile) {
        setUploadProgress(0);
        setDownloadURL(null);
        setFile(droppedFile);
      }
    } else {
      toast.error("Upload An Assignment First Before Solution");
    }
  };

  const handleFileChange = (e) => {
    setUploadProgress(0);
    setDownloadURL(null);
    setFile(e.target.files[0]);
  };

  const handleSolutionUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload failed", error);
      },
      () => {
        // Get download URL after upload
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData({
            ...formData,
            file_link: url,
          });
          setDownloadURL(url);
        });
      }
    );
  };
  useEffect(() => {
    if (file) {
      setDownloadURL(null);
      handleSolutionUpload();
    }
  }, [file]);

  return (
    <Box
      className="innersection"
      display="flex"
      justifyContent="center"
      // alignItems="center"
      flexDirection={"column"}
      // minHeight="100vh"
      pl={{ sm: 2, md: 5 }}
      pr={{ sm: 2, md: 5 }}
      bgcolor="background.default"
      marginBottom={8}
      // p={3}
    >
      {/* <h1
        style={{
          marginBottom: 60,
          fontSize: 40,
          marginTop: 40,
          color: "#D15242",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        E1x Academy Assigment Uploading Portal
      </h1> */}

      <h1
        style={{
          marginTop: 40,
          fontWeight: 600,
          marginBottom: 10,
        }}
      >
        Upload Solution
      </h1>
      <Box
        bgcolor="#f7f7f7"
        p={4}
        // mt={5}
        borderRadius={2}
        // boxShadow={3}
        // maxWidth={500}
        width="100%"
      >
        <Box
          p={4}
          border="2px dashed"
          borderColor={isDragging ? "primary.main" : "grey.400"}
          borderRadius={2}
          textAlign="center"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <h1
            style={{
              marginBottom: 20,
              fontSize: 25,
              fontWeight: 600,
            }}
          >
            Drag & Drop a File
          </h1>
          <Button
            onClick={() => {
              setUploadProgress(0);
              setDownloadURL("");
            }}
            variant="contained"
            component="label"
            color="warning"
            style={{
              background: "#D15242",
              boxShadow: "none",
            }}
          >
            Browse Files
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <p
            style={{
              marginTop: 20,
            }}
          >
            You can upload only one file for an assignment. Maximum file size:
            10 MB
          </p>
          {file && (
            <Typography variant="body2" color="textSecondary" mt={2}>
              Selected File: {file.name}
            </Typography>
          )}
        </Box>

        {/* Upload Progress */}
        {downloadURL ? (
          <h1
            style={{
              marginTop: 10,
            }}
          >
            File Uploaded Successfully
          </h1>
        ) : (
          uploadProgress > 0 && (
            <Box mt={3}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" mt={2}>
                Upload Progress: {Math.round(uploadProgress)}%
              </Typography>
            </Box>
          )
        )}
      </Box>
      <Button
        onClick={handlesubmit}
        disabled={formData.solution_file === ""}
        size="large"
        variant="contained"
        style={{
          background: "#d15242",
          minHeight: 50,
          marginTop: 50,
        }}
      >
        {loading ? "Loading..." : "Submit"}
      </Button>
    </Box>
  );
};

export default UploadAnswers;
