import React from "react";
import { ClipLoader } from "react-spinners";
import { useIsMobile } from "./hooks/useIsMobile";

const LoadingSpinner = () => {
  const mobile = useIsMobile();
  return (
    <div
      className="flex flex-col items-center justify-center "
      style={{
        height: mobile ? "100vh" : "50vh",
      }}
    >
      <ClipLoader size={80} color="#d15424" loading={true} />
      <h1
        style={{
          marginTop: 10,
        }}
      >
        Loading...
      </h1>
    </div>
  );
};

export default LoadingSpinner;
