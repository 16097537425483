import React from "react";
import Course from "../components/Courses/Course";
import MainLayouts from "../layout/MainLayouts";

const Courses = () => {
  return (
    <MainLayouts>
      <div className="custom-grow-background">
        <Course />
      </div>
    </MainLayouts>
  );
};

export default Courses;
