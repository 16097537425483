import { motion, useAnimation } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { CourseDetailContext } from "../../services/CourseDetails";
const StudentReviews = () => {
  const { uuid } = useParams();
  const [reviews, setReviews] = useState([]);
  const { courses, fetchCourseDetails } = useContext(CourseDetailContext);
  const fetchCourseReviews = async (uuid) => {
    try {
      const authToken = localStorage.getItem("token");

      if (!authToken) {
        console.error("Authentication token is missing");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await fetch(
        `https://e1xstaging.edoubleone.com/api/reviews/course/${uuid}`,
        config
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();

      setReviews(data);
    } catch (error) {
      console.error("Error fetching course reviews:", error);
    }
  };
  useEffect(() => {
    if (uuid) {
      fetchCourseDetails(uuid);
      fetchCourseReviews(uuid);
    }
  }, [uuid]); //

  const [animateImage, setAnimateImage] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once when the element comes into view
  });
  useEffect(() => {
    if (inView) {
      // When the component is in view, trigger the animation
      controls.start({
        y: 0, // The final position in the y-axis
        opacity: 1, // Set opacity to 1 to make it visible
        transition: {
          duration: 0.5, // Adjust the duration as needed
        },
      });
      setAnimateImage(true);
    }
  }, [controls, inView]);

  return (
    <div className="max-w-6xl mx-auto py-10 px-4">
      <motion.div
        className="lg:w-2/5 w-full"
        initial={{ y: 100, opacity: 0 }}
        animate={controls}
        ref={ref}
      >
        <div className="background-card  mb-4 px-4 py-10 space-y-8">
          {reviews.slice(0, 1).map((review, index) => (
            <div key={index} className="flex-1">
              <div className="background-card mb-4 px-4 py-6 space-y-8">
                <p className="text-xs">{review.comment}</p>
                <div className="flex items-center justify-between border-b pb-1">
                  <div>
                    <p className="font-bold">{review.firstname}</p>
                    <p className="text-xs">{review.date}</p>
                  </div>
                  <div>
                    <img
                      src={review.profile_img}
                      className="w-12 h-12"
                      alt="student Reviews"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default StudentReviews;
