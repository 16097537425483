import React, { useContext, useState } from "react";
import { uploadHelmper } from "../../uploadCDN";
import CircularProgress from "../Generic/CircularProgress";
import { UpdateUserInfo } from "../../context/actions/auth";
import { GlobalContext } from "../../context";

const ProfilePictureUploader = () => {
  const { authDispatch, authState } = useContext(GlobalContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleImageChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      const res = await uploadHelmper(file).then((res) => {
        UpdateUserInfo(
          {
            profile_img: res?.url,
          },
          authDispatch
        );
      });

      setSelectedImage(URL.createObjectURL(file));
    }
    setLoading(false);
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-48 h-48 relative">
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          {loading && <CircularProgress />}
        </div>
        {selectedImage || authState?.data?.user?.profile_img ? (
          <img
            src={selectedImage || authState?.data?.user?.profile_img}
            alt="Selected"
            className="w-full h-full object-cover rounded-full"
          />
        ) : (
          <div
            className="w-full h-full flex items-center justify-center bg-gray-300 rounded-full"
            style={{
              position: "relative",
            }}
          >
            <svg
              className="w-24 h-24 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 3a7 7 0 110 14 7 7 0 010-14zm0 2a5 5 0 100 10A5 5 0 0010 5z"
                clipRule="evenodd"
              />
              <path d="M14.7 14.7a7 7 0 01-9.4 0l1.42-1.42a5 5 0 006.56 0l1.42 1.42z" />
            </svg>
          </div>
        )}
      </div>
      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="hidden"
      />
      <button
        onClick={handleClick}
        className="mt-4 px-4 py-2 bg-blue-500 text-white text-lg rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
      >
        {selectedImage ? "Update Profile Picture" : "Add Profile Picture"}
      </button>
    </div>
  );
};

export default ProfilePictureUploader;
