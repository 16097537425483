import React, { useEffect, useReducer } from "react";
import { fetchUserLocation, getCurrentUser } from "./actions/auth";
import { loadCountries } from "./actions/countries";
import {
  FetchallLiveCousrse,
  FetchallOnlineCousrse,
  FetchCoursesCategory,
} from "./actions/courses";
import authReducer, { defaultAuth } from "./reducers.tsx/authReducer";
import genericReducer, { defaultData } from "./reducers.tsx/genericReducer";

const genericData = {
  error: null,
  loading: false,
  data: [],
};
const locationData = {
  error: null,
  loading: false,
  data: {},
};

export const GlobalContext = React.createContext({
  authState: defaultAuth,
  authDispatch: () => {},
  countryCodeState: genericData,
  countryCodeDispatch: () => {},
  onlineCoursesState: defaultData,
  onlineCoursesDispatch: () => {},
  liveCoursesState: defaultData,
  liveCoursesDispatch: () => {},
  userCoursesState: defaultData,
  userCoursesDispatch: () => {},
  categoryState: defaultData,
  categoryDispatch: () => {},
  userLocation: locationData,
  userLocationDispatch: () => {},
  communityState: defaultData,
  communityDispatch: () => {},
  scheduleState: defaultData,
  scheduleDispatch: () => {},
});

function getInitialState(key, defaultState) {
  const storedState = localStorage.getItem(key);
  return storedState ? JSON.parse(storedState) : defaultState;
}

export default function GlobalProvider({ children }) {
  const [authState, authDispatch] = useReducer(
    authReducer,
    getInitialState("authState", defaultAuth)
  );
  const [countryCodeState, countryCodeDispatch] = useReducer(
    genericReducer,
    getInitialState("countryCodeState", genericData)
  );
  const [scheduleState, scheduleDispatch] = useReducer(
    genericReducer,
    getInitialState("scheduleState", genericData)
  );
  const [communityState, communityDispatch] = useReducer(
    genericReducer,
    getInitialState("communityState", genericData)
  );
  const [categoryState, categoryDispatch] = useReducer(
    genericReducer,
    getInitialState("categoryState", genericData)
  );
  const [onlineCoursesState, onlineCoursesDispatch] = useReducer(
    genericReducer,
    getInitialState("onlineCoursesState", genericData)
  );
  const [liveCoursesState, liveCoursesDispatch] = useReducer(
    genericReducer,
    getInitialState("liveCoursesState", genericData)
  );
  const [userCoursesState, userCoursesDispatch] = useReducer(
    genericReducer,
    getInitialState("userCoursesState", genericData)
  );
  const [userLocation, userLocationDispatch] = useReducer(
    genericReducer,
    getInitialState("userLocation", locationData)
  );

  useEffect(() => {
    // Fetch initial data
    fetchUserLocation(userLocationDispatch);
    loadCountries(countryCodeDispatch);
    getCurrentUser(authDispatch);
    FetchallLiveCousrse(liveCoursesDispatch);
    FetchallOnlineCousrse(onlineCoursesDispatch);
    FetchCoursesCategory(categoryDispatch);
  }, []);

  // Save states to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("authState", JSON.stringify(authState));
  }, [authState]);

  useEffect(() => {
    localStorage.setItem("countryCodeState", JSON.stringify(countryCodeState));
  }, [countryCodeState]);

  useEffect(() => {
    localStorage.setItem("communityState", JSON.stringify(communityState));
  }, [communityState]);

  useEffect(() => {
    localStorage.setItem("categoryState", JSON.stringify(categoryState));
  }, [categoryState]);
  useEffect(() => {
    localStorage.setItem("categoryState", JSON.stringify(categoryState));
  }, [categoryState]);

  useEffect(() => {
    localStorage.setItem(
      "onlineCoursesState",
      JSON.stringify(onlineCoursesState)
    );
  }, [onlineCoursesState]);

  useEffect(() => {
    localStorage.setItem("liveCoursesState", JSON.stringify(liveCoursesState));
  }, [liveCoursesState]);

  useEffect(() => {
    localStorage.setItem("scheduleState", JSON.stringify(scheduleState));
  }, [scheduleState]);

  useEffect(() => {
    localStorage.setItem("userLocation", JSON.stringify(userLocation));
  }, [userLocation]);

  return (
    <GlobalContext.Provider
      value={{
        authState,
        authDispatch,
        countryCodeState,
        countryCodeDispatch,
        onlineCoursesState,
        onlineCoursesDispatch,
        liveCoursesState,
        liveCoursesDispatch,
        userCoursesState,
        userCoursesDispatch,
        userLocation,
        userLocationDispatch,
        communityState,
        communityDispatch,
        categoryState,
        categoryDispatch,
        scheduleDispatch,
        scheduleState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
