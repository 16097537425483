import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import bg2 from "../assets/images/businessman-with-graph-laptop.jpg"; // Another background image
import bg1 from "../assets/images/financce.png";
import Animator from "../components/Aminator/Animator";
import { useIsMobile } from "../components/hooks/useIsMobile";

export default function AuthLayout({ children }) {
  const mobile = useIsMobile();
  const [backgroundImage, setBackgroundImage] = useState(bg1); // Initial background image

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImage((prevBg) => (prevBg === bg1 ? bg2 : bg1));
    }, 5000); // Change background every 5 seconds

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  return (
    <div
      style={{
        padding: mobile ? 0 : "1% 7%",
        background: "#f1f1f1",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Animator variant="zoom" direction="left" delay={400} timeout={400}>
        <div
          style={{
            position: "relative",
            // minHeight: mobile ? "100vh" : "80vh",

            maxHeight: "100vh",
            borderRadius: mobile ? 10 : 30,
            background: "#fff",
            maxWidth: mobile ? "96%" : "48%",
            minWidth: mobile ? "90%" : "45%",
            margin: "auto",
          }}
        >
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} md={12} lg={12}>
              <div
                style={{
                  padding: mobile ? "10% 5%" : "9% 8%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: "#000",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                {children}
              </div>
            </Grid>
          </Grid>
        </div>
      </Animator>
    </div>
  );
}
