import { Close } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import nyscBg from "../../../assets/images/nyscright.png";
import { useIsMobile } from "../../../components/hooks/useIsMobile";
import { GlobalContext } from "../../../context";
import { signupHandler } from "../../../context/actions/auth";
import { fetchSignUpLocation } from "../../../utility";

const campData = [
  { state: "Abia", camp: "Umuahia Camp" },
  { state: "Adamawa", camp: "Lifecamp, Yola" },
  { state: "Akwa Ibom", camp: "Ikot Itie Udung Camp" },
  { state: "Anambra", camp: "Mkpoko Camp, Umunze" },
  { state: "Bauchi", camp: "Bauchi Camp" },
  { state: "Bayelsa", camp: "Sagbama Camp" },
  { state: "Benue", camp: "Gboko Camp" },
  { state: "Borno", camp: "Maiduguri Camp" },
  { state: "Cross River", camp: "Obubra Camp" },
  { state: "Delta", camp: "Issele-Uku Camp" },
  { state: "Ebonyi", camp: "Abakaliki Camp" },
  { state: "Edo", camp: "Okada Camp" },
  { state: "Ekiti", camp: "Ise-Orun Camp" },
  { state: "Enugu", camp: "Awgu Camp" },
  { state: "Gombe", camp: "Gombe Camp" },
  { state: "Imo", camp: "Nekede Camp" },
  { state: "Jigawa", camp: "Dutse Camp" },
  { state: "Kaduna", camp: "Manchok Camp" },
  { state: "Kano", camp: "Kano Camp" },
  { state: "Kogi", camp: "Anyigba Camp" },
  { state: "Kwara", camp: "Yikpata Camp" },
  { state: "Lagos", camp: "Iyana-Ipaja Camp" },
  { state: "Nasarawa", camp: "Keffi Camp" },
  { state: "Niger", camp: "Paiko Camp" },
  { state: "Ogun", camp: "Sagamu Camp" },
  { state: "Ondo", camp: "Ikare Camp" },
  { state: "Osun", camp: "Ede Camp" },
  { state: "Oyo", camp: "Iseyin Camp" },
  { state: "Plateau", camp: "Mangu Camp" },
  { state: "Rivers", camp: "Nonwa Camp" },
  { state: "Sokoto", camp: "Wammako Camp" },
  { state: "Taraba", camp: "Jalingo Camp" },
  { state: "Yobe", camp: "Damaturu Camp" },
  { state: "Zamfara", camp: "Talata Mafara Camp" },
  { state: "Federal Capital Territory (FCT)", camp: "Dabo Camp" },
];

const SignupDialog = ({ open, handleClose, apiUrl, onSubmit }) => {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    camp: "", // Changed to represent state and camp together
    email: "",
    location: {
      country: "",
      region: "",
      city: "",
    },
    user_type: "nysc",

    telephone: "",
    password: "",
    country_code: "+234",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const mobile = useIsMobile();
  useEffect(() => {
    fetchSignUpLocation().then((res) => {
      setCountry(res?.country);
      setRegion(res?.region);
      setCity(res?.city);
    });
  }, []);

  // Update location in formData when country, region, or city changes
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      location: { country, region, city },
    }));
  }, [country, region, city]);

  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const { authDispatch } = useContext(GlobalContext);
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await signupHandler(formData, authDispatch);
    if (response) {
      navigate("/vonverify");
      onSubmit();

      // navigate("/vonverify");
    } else {
      onSubmit();
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonBase onClick={handleClose} color="primary">
            <Close style={{ color: "red" }} />
          </ButtonBase>
        </div>
        <Grid container spacing={4} sx={{ height: "100%" }}>
          {/* Left Side: Signup Form */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              sx={{
                p: { sm: 1, md: 4 },
                backgroundColor: mobile ? "" : "#f9f9f9",
                borderRadius: 2,
                width: "100%",
              }}
            >
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Sign up E1X
              </Typography>
              <Typography variant="body2" gutterBottom>
                Already have an account? <Link href="#">Login</Link>
              </Typography>

              <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
                <div>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    margin="normal"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    margin="normal"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                    required
                  />
                </div>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  margin="normal"
                  label="Camp"
                  name="camp" // Fixed the name to use 'camp'
                  value={formData.camp}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="">
                    <em>Select State and Camp</em>
                  </MenuItem>
                  {campData.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={`${item.state} - ${item.camp}`}
                    >
                      {`${item.state} - ${item.camp}`}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  margin="normal"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <TextField
                  fullWidth
                  label="Telephone"
                  variant="outlined"
                  margin="normal"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+234</InputAdornment>
                    ),
                  }}
                  required
                />
                <TextField
                  fullWidth
                  label="Password"
                  variant="outlined"
                  margin="normal"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  sx={{
                    mt: 3,
                    backgroundColor: "#d15424",
                    "&:hover": { backgroundColor: "#d15424" },
                  }}
                >
                  {loading ? "Creating Account..." : "Create Account"}
                </Button>
              </Box>
            </Box>
          </Grid>

          {!mobile && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={nyscBg}
                alt="illustration"
                width="100%"
                style={{ height: "100%", objectFit: "cover" }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SignupDialog;
