import { format } from "date-fns";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../context";
import { date } from "../../../utility";
import { useIsMobile } from "../../hooks/useIsMobile";
export default function UpcomingAgenda({
  topic,
  start_time,
  course_id,
  course,
  time,
  location,

  courses,
}) {
  const mobile = useIsMobile();
  const navigate = useNavigate();
  const {
    liveCoursesState: { data },
  } = useContext(GlobalContext);

  const courseInfo =
    (data && data?.filter((cur) => cur.course_id === course_id)) || [];

  return (
    <div
      onClick={() => {
        navigate(`/live-course/${course?.uuid}`);
      }}
      style={{
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10,
        // flexWrap: "wrap",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
        }}
      >
        <img
          src={courses[0]?.image_url}
          style={{
            height: mobile ? 30 : 60,
            width: mobile ? 30 : 60,
            objectFit: "cover",
            borderRadius: 50,
            marginRight: 10,
            marginBottom: mobile ? 18 : 5,
          }}
        />
        <div
          style={{
            width: "90%",
          }}
        >
          <h1
            style={{
              lineBreak: "anywhere",
              fontSize: mobile ? 12 : 15,
            }}
          >
            {courses[0]?.title}
            {"-"} {topic}
          </h1>

          <p
            style={{
              fontSize: mobile ? 10 : 13,
              color: "#aaa",
            }}
          >
            {location === "NG"
              ? date(time?.wat?.date || new Date()) +
                " " +
                format(time?.wat?.date || new Date(), "h:mm a")
              : date(time?.est?.date || new Date()) +
                " " +
                format(time?.est?.date || new Date(), "h:mm a")}
          </p>
        </div>
      </div>
      <div
        style={{
          width: "30%",
          marginBottom: 22,
        }}
      >
        <h1
          style={{
            fontSize: 13,
            color: "#aaa",
            textAlign: "end",
            fontSize: mobile ? 10 : 15,
          }}
        >
          Course Type
        </h1>
        <p
          style={{
            color: "#d15424",
            textAlign: "end",
            fontSize: mobile ? 10 : 15,
          }}
        >
          Live Sessions
        </p>
      </div>
    </div>
  );
}
