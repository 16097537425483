import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import VonDisplayText from "../VonDisplayText/VonDisplayText";

import { GlobalContext } from "../../../context";
import Discount from "../../Generic/Discount";
export default function VonCourseOnlineCard({
  images,
  title,
  image_url,

  id,

  price,
  uuid,
  description,

  is_free,
  course_level_id,

  discount,
  instructor,
  currency,
  what_you_will_learn,
  review,
  view = false,
}) {
  const htmlContentFromAPI = description;
  const { userLocation } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleViewDetails = () => {
    navigate(`/online-course/${uuid}`);
  };
  return (
    <div
      onClick={() => handleViewDetails(uuid)}
      style={{
        background: `url(${image_url})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "darken",
        borderRadius: 15,
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease-in-out",
      }}
      className="relative w-full  min-[480px]:w-[100%] md:w-[30%] lg:w-[24%] h-[300px] cursor-pointer p-4 overflow-hidden group text-white before:absolute before:bg-[#00000086] before:w-full before:h-full before:top-0 before:left-0 before:rounded-lg mb-[1%]"
    >
      {(is_free === 1 || is_free) && (
        <div
          style={{
            position: "absolute",
            right: 0,

            background: "#2c75e4",
            padding: 5,
            minWidth: 80,
            borderTopLeftRadius: 15,
            borderBottomLeftRadius: 15,
          }}
        >
          <h1
            style={{
              marginLeft: "10px",
            }}
          >
            Free
          </h1>
        </div>
      )}
      {discount && is_free === 0 && userLocation.data === "NG" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <div style={{}}>
            <Discount value={discount} />
          </div>
        </div>
      )}
      <div className="absolute bottom-[20%] translate-y-[85%] group-hover:translate-y-1/4 delay-0 duration-200 transition-transform ease-[ease]">
        <p>{course_level_id}</p>
        <p>{title}</p>
        <div className="invisible flex gap-1 mb-[7%]">
          <p>{currency}</p>
          <p>{price}</p>
        </div>
        <p className="">
          <VonDisplayText htmlContent={htmlContentFromAPI} maxLength={100} />
        </p>
      </div>
    </div>
  );
}
