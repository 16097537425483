import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import About from "./page/About";
import CheckOutPage from "./page/CheckOutPage";
import ContactPage from "./page/ContactPage";
import OnlineCourseDetail from "./page/CourseDetail";
import Courses from "./page/Courses";
import LandingPage from "./page/LandingPage/index";
import LiveCourses from "./page/LiveCourse";
import OnlineCourseDetailPage from "./page/OnlineCourseDetail";
import Payment from "./page/Payement";
import SuccessfulPage from "./page/SuccessfulPage";

import Dashboard from "./components/Dashboard/Dashboard";

import NotFound from "./page/NotFound";

import MyCourse from "./components/Dashboard/MyCourse";
import PrivateRoute from "./components/PrivateRoute";
import Callback from "./page/Callback";
import LiveCourseDetailPage from "./page/LiveCourseDetails";

import Preloader from "./components/preloader";

import VerifyEmailComponent from "./components/VerifyEmailComponent";

// VoN
import { GoogleOAuthProvider } from "@react-oauth/google";
import { logEvent } from "firebase/analytics";
import VonAllCourses from "./components/AllVon/VonAllCourses/VonAllCourses";
import VonVerifyEmail from "./components/AllVon/VonVerifyEmail/VonVerifyEmail";
import DashBoardNew from "./components/Dashboard/DashBoardNew";
import Settings from "./components/Dashboard/Settings";
import VerifyEmail from "./components/VerifyEmailComponent";
import { GlobalContext } from "./context";
import { FetchUserCoureses } from "./context/actions/courses";
import { analytics } from "./firebase";
import "./global.css";
import UploadAnswers from "./page/Assignments/UploadAnswers";
import AugustCohort from "./page/AugustCohort";
import AugustWaitListv2 from "./page/AugustWaitListv2";
import BaRegistration from "./page/Ba-Registration";
import BecomeASposor from "./page/BecomeASposor";
import Categories from "./page/Category/Categories";
import Community from "./page/Community";
import DataAnalyst from "./page/DataAnalyticsForm";
import Engagement from "./page/Engagement";
import FeedBack from "./page/FeedBack";
import Instructors from "./page/Instructors/Instructors";
import JanuaryWaitList from "./page/JanuaryWaitList";
import Nysc from "./page/Nysc";
import PrivacyPolicy from "./page/PrivacyPolicy";
import ProjectPage from "./page/Projects/Index";
import Sponsor from "./page/Sponsor";
import SponsorApply from "./page/Sponsor-Apply";
import SummerForm from "./page/SummerForm";
import ForgottenPasswordPage from "./page/auth/ForgottenPassword";
import ResetPasswordPage from "./page/auth/ResetPassword";
import SignUpPage from "./page/auth/SignUp";
import LoginPage from "./page/auth/login";
import { getDescriptiveName } from "./utility";

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: location.pathname,
      page_title: getDescriptiveName(location.pathname),
    });
  }, [location]);
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Set loading to false when your app is ready to load
    }, 2000); // Adjust the delay time as needed
  }, []);
  return (
    <BrowserRouter>{loading ? <Preloader /> : <AppContent />}</BrowserRouter>
  );
}

function AppContent() {
  const {
    authState: { loggedIn, data },
    userCoursesDispatch,
    countryCodeDispatch,
  } = useContext(GlobalContext);

  const location = useLocation();

  const isDashboardPath = location.pathname.startsWith("/dashboard");

  usePageTracking();
  const loadUserData = async () => {
    if (loggedIn) {
      FetchUserCoureses(userCoursesDispatch);
    }
  };
  useEffect(() => {
    loadUserData();
  }, [loggedIn]);

  return (
    <>
      <GoogleOAuthProvider clientId="1077958454111-kaqpn5cl373997strsunh6v8i9ms5e17.apps.googleusercontent.com">
        <Routes>
          <Route
            path="/"
            element={
              loggedIn ? (
                data?.user?.is_email_verified === false ? (
                  <VerifyEmail />
                ) : (
                  <DashBoardNew />
                )
              ) : (
                <LandingPage />
                // <VonRenew />
              )
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="instructors-upload" element={<Instructors />} />
          <Route path="/august/cohort" element={<AugustCohort />} />
          <Route path="/courses/categories" element={<Categories />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/checkout" element={<CheckOutPage />} />
          <Route path="/projects" element={<ProjectPage />} />
          <Route path="/payment-complete/:reference" element={<Callback />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/success" element={<SuccessfulPage />} />
          <Route path="/online-course" element={<Courses />} />
          <Route path="/sponsor/apply" element={<SponsorApply />} />
          <Route path="/summer/registration" element={<SummerForm />} />
          <Route
            path="/online-course/:uuid"
            element={<OnlineCourseDetailPage />}
          />
          <Route path="/course-video/:uuid" element={<OnlineCourseDetail />} />

          <Route path="/live-course" element={<LiveCourses />} />
          <Route path="/sponsor/participate" element={<BecomeASposor />} />
          <Route path="/summer/apply" element={<SummerForm />} />
          <Route path="/waitlist/register" element={<JanuaryWaitList />} />
          <Route
            path="/waitlist/august/applyv2"
            element={<AugustWaitListv2 />}
          />
          <Route path="/live-course/:uuid" element={<LiveCourseDetailPage />} />
          <Route path="/sign-in" element={<LoginPage />} />
          <Route path="/community" element={<Community />} />
          {/* <Route path="/signup" element={<SignUpPage />} /> */}
          {/* <Route path="/forgotpassword" element={<ResetPasswordPage />} /> */}
          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/nysc" element={<Nysc />} />
          <Route path="/user/assignment/submit" element={<UploadAnswers />} />
          <Route path="/verify-email" element={<VerifyEmailComponent />} />
          <Route path="/verify-useremail" element={<VerifyEmailComponent />} />
          <Route path="/all-courses/:uuid" element={<VonAllCourses />} />
          <Route path="/profile" element={<Settings />} />

          {/* Dashboard */}
          <Route
            path="/dashboard"
            element={<PrivateRoute Element={<Dashboard />} />}
          />

          <Route
            path="/dashboard/my-course"
            element={
              <PrivateRoute
                Element={<MyCourse />}
                path="/dashboard/my-course"
              />
            }
          />

          <Route path="/reset-password" element={<ResetPasswordPage />} />

          <Route path="/sponsor" element={<Sponsor />} />
          <Route path="/ba-registration" element={<BaRegistration />} />
          <Route
            path="/data-analyst-may-cohort-2024-form"
            element={<FeedBack />}
          />
          <Route
            path="/data-analyst-may-cohort-form"
            element={<DataAnalyst />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/forgot-password" element={<ForgottenPasswordPage />} />
          <Route path="/invite" element={<Engagement />} />
          <Route path="/vonverify" element={<VonVerifyEmail />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </GoogleOAuthProvider>

      {/* {!isVonRenewPath && !isVonVerifyPath && !isSignInPath && !isSignUpPath && <CookieConsent />} */}
    </>
  );
}

//
// To https://github.com/edoubleone/e1xacademynew.git
//    3f97c99..9a6b522  main -> main
export default App;
