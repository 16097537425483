import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import client from "../../api/client";
import ProjectItem from "../../components/Projects/ProjectItem";
import { GlobalContext } from "../../context";
import { storage } from "../../firebase";
import MainLayouts from "../../layout/MainLayouts";
import { errorMessage } from "../../utility";

function ProjectPage() {
  const {
    authState: { loggedIn },
  } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    project_id: "",
    file_link: "",
    image: "",
    title: "",
    description: "",
  });
  const [projectId, setProjectId] = useState();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #000",

      padding: 10,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginLeft: 40, // Adjust this value according to your image size
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };
  const [projectIdLoading, setProjectIdLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadURL, setDownloadURL] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && formData?.problem_file) {
      setUploadProgress(0);
      setDownloadURL(null);
      setFile(droppedFile);
    } else {
      toast.error("Upload An Assignment First Before Solution");
    }
  };

  const handleFileChange = (e) => {
    setUploadProgress(0);
    setDownloadURL(null);
    setFile(e.target.files[0]);
  };

  const handleSolutionUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload failed", error);
      },
      () => {
        // Get download URL after upload
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData({
            ...formData,
            file_link: url,
          });
          setDownloadURL(url);
        });
      }
    );
  };

  useEffect(() => {
    if (file) {
      setDownloadURL(null);
      handleSolutionUpload();
    }
  }, [file]);

  const [open, setOpen] = useState(false);
  const [file1, setFile1] = useState(null);
  const [uploadProgress1, setUploadProgress1] = useState(0);
  const [downloadURL1, setDownloadURL1] = useState("");

  // Image upload and progress tracking
  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile1(selectedFile);
      uploadImage(selectedFile);
    }
  };

  const uploadImage = (file1) => {
    const storageRef = ref(storage, `uploads/${file1.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file1);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress1(progress);
      },
      (error) => {
        console.error("Upload error:", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDownloadURL1(url);
          setFormData({
            ...formData,
            image: url,
          });
          console.log("File available at", url);
        });
      }
    );
  };
  const [fetchProjectLoading, setFetchProjectLoading] = useState(false);
  const [data, setData] = useState([]);
  const loadProjectData = async () => {
    setFetchProjectLoading(true);
    try {
      const res = (await client.get("/projects/showcase")).data;
      console.log("response from api", res[0]);
      setData(res);
    } catch (error) {
      console.log(error?.response);
    }
    setFetchProjectLoading(false);
  };
  useEffect(() => {
    loadProjectData();
    loadData();
  }, []);
  const loadData = async () => {
    setProjectIdLoading(true);
    try {
      const res = (await client.get("/projects/publish")).data;
      // console.log("response from api", res);
      setProjectId(res);
    } catch (error) {
      console.log(error?.response);
    }
    setProjectIdLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  const submitData = async () => {
    setLoading(true);
    try {
      const res = (await client.post("projects/publish", formData)).data;
      toast.success("Project Uploaded Successfullly");
      setOpen(false);
      loadProjectData();
    } catch (error) {
      toast.error(errorMessage(error));
    }
    setLoading(false);
  };
  const [data1, setData1] = useState([]);
  useEffect(() => {
    if (searchParam === "") {
      setData1([...data]);
    } else {
      const res = data?.filter((cur) =>
        cur?.title?.toLowerCase().includes(searchParam?.toLowerCase())
      );
      setData1(res);
    }
  }, [searchParam, data]);

  return (
    <MainLayouts>
      <Dialog
        fullWidth
        maxWidth="sm" // Adjust for mobile
        open={open}
        onClose={() => setOpen(!open)}
      >
        <div
          style={{
            padding: "5%",
            borderRadius: 20,
            minHeight: "50vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h1 style={{ marginBottom: 10 }}>Featured Image</h1>
              <ButtonBase
                style={{
                  background: "#f7f7f7",
                  borderRadius: 8,
                  height: "40vh", // Adjusted for mobile
                  width: "100%",
                  position: "relative",
                }}
              >
                {uploadProgress1 > 0 && uploadProgress1 < 100 && (
                  <CircularProgress
                    variant="determinate"
                    value={uploadProgress1}
                    size={60}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                {downloadURL1 ? (
                  <div style={{ position: "relative" }}>
                    <ButtonBase
                      onClick={() => {
                        setDownloadURL1("");
                        setFile1("");
                      }}
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }}
                    >
                      <Delete style={{ color: "red" }} />
                    </ButtonBase>
                    <img
                      src={downloadURL1}
                      alt="Uploaded"
                      style={{
                        width: "100%",
                        height: "40vh",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : (
                  <Button
                    onClick={() => {
                      setUploadProgress(0);
                      setDownloadURL("");
                    }}
                    variant="contained"
                    component="label"
                    color="warning"
                    style={{
                      background: "#D15242",
                      boxShadow: "none",
                    }}
                  >
                    Select File
                    <input
                      type="file"
                      accept="image"
                      hidden
                      onChange={handleImageUpload}
                    />
                  </Button>
                )}
              </ButtonBase>
            </Grid>

            <Grid item xs={12}>
              <h1 style={{ marginBottom: 10 }}>Project Info</h1>
              <TextField
                value={formData.title}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    title: e.target.value,
                  });
                }}
                fullWidth
                size="small"
                style={{ marginBottom: 10 }}
                placeholder="Project Title"
              />
              <TextField
                value={formData.description}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    description: e.target.value,
                  });
                }}
                fullWidth
                size="small"
                placeholder="Description"
              />

              <div className="mb-2 mt-2">
                {projectIdLoading ? (
                  <p>Loading...</p>
                ) : (
                  <Select
                    options={projectId?.map((cur) => ({
                      label: <div>{cur?.title}</div>,
                      value: cur?.id,
                    }))}
                    onChange={(selectedOption) => {
                      setFormData({
                        ...formData,
                        project_id: selectedOption?.value,
                      });
                    }}
                    styles={customStyles}
                    isClearable
                    placeholder="Select Category"
                    required
                  />
                )}
              </div>

              <Box mt={2} p={3} bgcolor="#f7f7f7" borderRadius={2} width="100%">
                <Box
                  p={2}
                  border="2px dashed"
                  borderColor={isDragging ? "primary.main" : "grey.400"}
                  borderRadius={2}
                  textAlign="center"
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <h1 style={{ marginBottom: 10 }}>Drag & Drop a File</h1>
                  <Button
                    onClick={() => {
                      setUploadProgress(0);
                      setDownloadURL("");
                    }}
                    variant="contained"
                    component="label"
                    color="warning"
                    style={{
                      background: "#D15242",
                      boxShadow: "none",
                    }}
                  >
                    Browse Files
                    <input type="file" hidden onChange={handleFileChange} />
                  </Button>
                  <p style={{ marginTop: 10 }}>Maximum file size: 10 MB</p>
                  {file && (
                    <Typography variant="body2" color="textSecondary" mt={2}>
                      Selected File: {file.name}
                    </Typography>
                  )}
                </Box>

                {downloadURL ? (
                  <h1>File Uploaded Successfully</h1>
                ) : (
                  uploadProgress > 0 && (
                    <Box mt={2}>
                      <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                      />
                      <Typography variant="body2" mt={2}>
                        Upload Progress: {Math.round(uploadProgress)}%
                      </Typography>
                    </Box>
                  )
                )}
              </Box>

              <Button
                disabled={
                  formData.description === "" ||
                  formData.file === "" ||
                  formData.image === "" ||
                  formData.project_id === ""
                }
                variant="contained"
                fullWidth
                style={{
                  background: "#d15242",
                  marginTop: 15,
                  color: "#fff",
                  marginBottom: 30,
                }}
                onClick={submitData}
              >
                {loading ? "Uploading..." : "Submit Project"}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Dialog>

      <div className="innersection">
        <div style={{ minHeight: "100vh", marginTop: 150 }}>
          <div className="flex items-center justify-between">
            <h1 style={{ fontSize: 30, fontWeight: 500 }}>
              Gallery of Final Projects
            </h1>
            {loggedIn && (
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                style={{
                  boxShadow: "none",
                  fontSize: 13,
                  background: "#d15242",
                }}
              >
                Add Project
              </Button>
            )}
          </div>

          <Divider
            style={{ borderWidth: 1.5, marginTop: 10, marginBottom: 20 }}
          />
          <h1 className="mb-4">
            Here are just some of the past few years’ final projects, randomly
            ordered!
          </h1>
          <TextField
            fullWidth
            placeholder="Search..."
            size="small"
            value={searchParam}
            onChange={(e) => {
              setSearchParam(e.target.value);
            }}
          />
          {fetchProjectLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 50,
              }}
            >
              <CircularProgress
                style={{
                  color: "#d15242",
                }}
              />
            </div>
          )}
          <div className="mt-6">
            {data1?.map((cur, i) => (
              <ProjectItem {...cur} key={i} />
            ))}
          </div>
        </div>
      </div>
    </MainLayouts>
  );
}

export default ProjectPage;
