import React, { useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../services/LoginAuthContext";
import { useNavigate } from "react-router-dom";
import MainLayouts from "../layout/MainLayouts";

const Payment = () => {
  const { user } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "http://localhost:3001/initialize-transaction", // Replace with your backend URL
        {
          email: user?.email,

          amount: 5000,
        }
      );

      // Do something with the response from the backend if needed
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle error or display an error message to the user
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <MainLayouts>
      <h2 onSubmit={handleSubmit}>Payment Page</h2>
    </MainLayouts>
  );
};

export default Payment;
