import AuthLayout from "../../layout/AuthLayouts";
import React, { useState } from "react";
import validator from "validator";
import axios from "axios";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import client from "../../api/client";
import CircularProgress from "../../components/Generic/CircularProgress";

import { TextField } from "@mui/material";
import Logo from "../../assets/images/logo.jpeg";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { useIsMobile } from "../../components/hooks/useIsMobile";
export default function ForgottenPasswordPage() {
  const mobile = useIsMobile();
  const [forgotPassword, setForgotPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!validator.isEmail(forgotPassword)) {
      errors.username = "Invalid email address";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handlePasswordChange = (e) => {
    setForgotPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await client.post(`/forgot-password/password/email`, {
          email: forgotPassword,
        });
        if (response)
          toast.success(
            "your reset password link have been sent to your email address "
          );
        navigate("/sign-in");
        setForgotPassword("");
      } catch (error) {
        console.error("reseting error:", error.response);
        toast.error("Error reseting Password. Please try again.");
        setErrors({ server: "Error reseting Password. Please try again." });
      }
      setLoading(false);
    }
  };
  return (
    <AuthLayout>
      <div
        style={{
          textAlign: mobile ? "center" : "left",
          display: "flex",
          alignItems: mobile ? "center" : "flex-start",
          flexDirection: "column",
          justifyContent: "center",
          width:"100%"
        }}
      >
        <img
          src={Logo}
          style={{
            width: 120,
            height: 60,
            objectFit: "contain",
            marginBottom: 10,
          }}
          alt="Logo"
        />
        <h1 className="text-2xl font-bold text-center mb-[5%]">
          Forgotten Password
        </h1>
        <p className="mb-[5%] text-center">
          A Reset Password Link Will Be Sent To This E-Mail
        </p>

        <div
          style={{
            width: "100%",
          }}
        >
          <form
            style={{
              width: "100%",
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              required
              label="Email"
              value={forgotPassword}
              onChange={handlePasswordChange}
              InputProps={{
                style: {
                  height: 55,
                  borderRadius: 5,
                  background: "#fff",
                  color: "#000",
                  fontWeight: 300,
                },
              }}
              fullWidth
              style={{
                marginBottom: 5,
              }}
            />

            {/* <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}>
                    <NavLink
                      style={{
                        width: mobile ? "60%" : "30%"
                      }}
                      to="/forgot-password"
                      className="text-sm mt-[2%] text-primary-color text-right block cursor-pointer"
                    >
                      Forgot password
                    </NavLink>
                  </div> */}
            <NavLink
              to="/sign-in"
              className="text-sm mt-[1%] text-primary-color text-right block cursor-pointer"
            >
              Login instead
            </NavLink>

            <button
              type="submit"
              className="w-full py-3 px-5 rounded text-sm bg-primary-color hover:bg-[#884c34] delay-0 duration-150 transition-colors ease-in text-white font-medium mt-[5%]"
            >
              {loading ? <CircularProgress /> : "Reset Password"}
            </button>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></div>
            </div>
            <p className="mt-[3%] text-sm text-center font-medium">
              Don't have an account?{" "}
              <Link to="/sign-up" className="text-primary-color">
                Register Now
              </Link>
              .
            </p>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
}
