import React from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
export default function EventSection() {
  const mobile = useIsMobile();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        flexDirection: mobile ? "column" : "row",
        minHeight: "100vh",

        background: "#fff",
        color: "#000",
      }}
    >
      <div
        style={{
          width: mobile ? "100%" : "60%",
        }}
      >
        <Calendar />
      </div>
      <div
        style={{
          width: mobile ? "100%" : "40%",
        }}
      >
        hfdhf
      </div>
    </div>
  );
}
