import { keyframes } from "@mui/material";
import { useNavigate, useNavigation } from "react-router-dom";
import MainLayouts from "../../layout/MainLayouts";
import Acheivement from "./Component/Acheivements";
import ChooseUs from "./Component/ChooseUs";
import CoursesSection from "./Component/CoursesSection";
import Header from "./Component/Header";
import { default as LiveCoursesSection } from "./Component/LiveCoursesSection";
function LandingPage() {
  const navigate = useNavigate();
  const bounceAnimation = keyframes`
  0%, 100% {
    transform: bottom: "30%",;
  }
  50% {
    transform: bottom: "50%",;
  }
`;

  return (
    <MainLayouts>
      <Header navigate={navigate} />
      <CoursesSection navigate={navigate} />
      <LiveCoursesSection navigate={navigate} />
      <Acheivement navigate={navigate} />
      <ChooseUs />
    </MainLayouts>
  );
}
export default LandingPage;
