import React from "react";

const StarIcon = ({ filled }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="w-6 h-6"
    style={{ color: filled ? "#f90" : "#ccc" }}
  >
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.283 3.946a1 1 0 00.95.69h4.21c.97 0 1.371 1.24.588 1.81l-3.404 2.479a1 1 0 00-.364 1.118l1.283 3.946c.3.921-.755 1.688-1.54 1.118L10 13.683l-3.404 2.479c-.784.57-1.84-.197-1.54-1.118l1.283-3.946a1 1 0 00-.364-1.118L2.57 9.373c-.784-.57-.382-1.81.588-1.81h4.21a1 1 0 00.95-.69l1.283-3.946z" />
  </svg>
);

export default StarIcon;
